import React from 'react'
import { c } from '../../../../utils'
import styles from './cartUpsell.module.scss'

export interface CartUpsellProps
  extends React.HTMLAttributes<HTMLAnchorElement> {
  message: string
  onClick: () => void
}

export function CartUpsell({
  className,
  message,
  onClick,
  ...props
}: CartUpsellProps) {
  return (
    <a className={c(styles.cartUpsell, className)} onClick={onClick} {...props}>
      {message}
    </a>
  )
}

export default CartUpsell
