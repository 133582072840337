import React from 'react';
import PropTypes from 'prop-types';
import '../../index.module.scss';
import styles from './button.module.scss';
import { c } from '../../utils';
import { addRipple } from '../effects';

/**
 * Primary UI component for user interaction
 * @param variant button variant
 * @param size button size
 * @param label contents
 * @param onClick click callback
 * @param theme optional theme selection
 * @param props additional props
 * @returns {JSX.Element}
 * @constructor
 */
export const Button = React.forwardRef(
  ({ className, variant = 'normal', size = 'medium', label, onClick, theme = 'default', children, ...props }, ref) => {
    const onClickHandler = (event) => {
      addRipple(event.currentTarget, event, styles.ripple);
      return onClick && onClick(event);
    };

    return (
      <button
        type="button"
        className={c(
          className,
          styles.button,
          styles[`button-${variant}`],
          styles[`button-${size}`],
          theme !== 'default' && `theme-${theme}`,
        )}
        onClick={onClickHandler}
        aria-label={props['aria-label'] || label}
        ref={ref}
        {...props}
      >
        {children || label}
      </button>
    );
  }
);

Button.propTypes = {
  /**
   * Button variant
   */
  variant: PropTypes.oneOf(['normal', 'primary', 'secondary', 'link', 'survey']),
  /**
   * button size
   */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /**
   * Button label.
   */
  label: PropTypes.string,
  /**
   * Optional callback everytime the button is clicked
   */
  onClick: PropTypes.func,
  /**
   * whether input is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Optional color theme to use
   */
  theme: PropTypes.oneOf(['default', 'enterprise', 'dark', 'join-flow'])
};
