import decode from 'jwt-decode';
const AUTH_TOKEN_KEY = 'ACCESS_TOKEN';

export const getAuthToken = () => {
  if (!window.localStorage) return null;
  
  const token = window.localStorage.getItem(AUTH_TOKEN_KEY);
  
  if (!token) return null;
  
  return JSON.parse(token);
};

export const setAuthToken = (accessToken) => {
  if (!window.localStorage) return null;
  window.localStorage.setItem(AUTH_TOKEN_KEY, JSON.stringify(accessToken));
};

export const hasOrderNumber = (id) => {
  const token = getAuthToken();

  if (!token) return false;

  const decoded = decode(token.accessToken);
  const order = decoded.orderId ? decoded.orderId : decoded.stripeOrderId;

  return order === id;
};

export const logout = async () => {
  window.localStorage.clear();
};
