import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './sizing-guide.module.scss';
import { ToggleButton } from '../../deprecated/ToggleButton'
import { c } from '../../../utils';
import { getSizingData, getSizingImage } from './data';
import { useTranslation, importTranslations } from '../../../utils/i18n';
import RemoveIcon from '../../../icons/Actions/ic_remove.svg';
import AddIcon from '../../../icons/Actions/ic_add.svg';

importTranslations('sizeGuide', require.context('./languages/', true));

const Cell = ({ values, units }) => {
  const value = values[units] || '--';
  if (Array.isArray(value)) {
    if (value[0] === 0) {
      return <td>&lt; {value[1]}</td>;
    }
    if (value[1] === Infinity) {
      return <td>{value[0]} &gt;</td>;
    }
    return <td>{value[0]}-{value[1]}</td>;
  }
  return <td>{value}</td>;
};

export const SizingGuide = ({ gender = 'unisex', name = 'bicep', type = 'BICEP', defaultToCm = false, className, ...props }) => {
  const { t } = useTranslation('sizeGuide');

  const MEASUREMENT_OPTIONS = {
    'in': { 'value': 'in', 'label': t('inches') },
    'cm': { 'value': 'cm', 'label': t('centimeters') }

  };
  const [currentUnits, setCurrentUnits] = useState(defaultToCm ? 'cm' : 'in');
  const [expanded, setExpanded] = useState();
  const toggleExpanded = () => setExpanded(!expanded);
  let units = ['in', 'cm'];
  if (defaultToCm) units = units.reverse();
  const measurementOptions = units.map(unit => MEASUREMENT_OPTIONS[unit]);

  const { chart, howToMeasure } = getSizingData(type);
  const imagePath = getSizingImage(type);
  const chartCols = Object.keys(chart);
  const chartRows = Array.from(
    Object.values(chart)
      .map(Object.keys)
      .reduce((set, keys) => { keys.forEach(set.add, set); return set; }, new Set()));

  return (
    <div
      className={c(className, styles.sizingGuide)}
      {...props}
    >
      <h1>{t('sizingGuide')}</h1>
      <p>
        {t('tapeMeasureText')}
      </p>
      <hr />
      <h2 style={{ marginTop: 12 }}>{gender}</h2>
      <h3>{name}</h3>
      <div className={styles.toggle}>
        <ToggleButton
          options={measurementOptions}
          onChange={setCurrentUnits}
          value={currentUnits}
          size='small'
        />
      </div>

      <div className={c(
        styles.tablesContainer,
        currentUnits !== units[0] && styles.slideLeft,
      )}
      >
        {units.map(unit => (
          <div key={unit}>
            <table>
              <thead>
                <tr>
                  <th />
                  {chartCols.map(col => (
                    <th key={col}>{t(`howToMeasureKeys.${col}`)}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {chartRows.map(row => (
                  <tr key={row}>
                    <th>{row}</th>
                    {chartCols.map(col => (
                      <Cell key={col} values={chart[col][row]} units={currentUnits} />
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
      </div>

      <hr />
      <div className={styles.accordionHeader} onClick={toggleExpanded}>
        <h3>{t('howToMeasure')}</h3>
        {expanded ? <RemoveIcon name='remove' /> : <AddIcon name='add' />}
      </div>
      <div className={c(styles.instructions, expanded && styles.expanded)}>
        <table>
          <tbody>
            {Object.keys(howToMeasure).map((key, i) => (
              <tr key={key}>
                <th>{i + 1} - {t(`howToMeasureKeys.${key}`)}</th>
                <td>{t(`howToMeasureValues.${howToMeasure[key]}`)}</td>
              </tr>
            ))}
          </tbody>
        </table>

        {imagePath && <img
          className={styles.preview}
          src={imagePath}
        />}
      </div>
      <hr />
    </div>);
};

// These are necessary to add as scope variables
SizingGuide.propTypes = {
  /**
   * Name of item
   */
  name: PropTypes.string,
  /**
   * Geneder label.
   */
  gender: PropTypes.string,
  /**
   * Type of sizing guide to show.
   */
  type: PropTypes.oneOf([
    'BICEP',
    'ARM',
    'MENS_TOP',
    'MENS_SHORTS',
    'MENS_BOXERS',
    'WOMENS_BRA',
    'WOMENS_LEGGINGS',
    'JOGGERS',
    'UNISEX_TOP',
    'UNISEX_TEE',
    'HYDROSLEEVE',
    'ASSOS_MENS_SHORTS',
    'ASSOS_WOMENS_SHORTS',
  ]),
  /**
   * Default to centimeters instead of inches.
   */
  defaultToCm: PropTypes.bool
};
