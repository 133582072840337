/* eslint-disable  @typescript-eslint/no-unused-expressions */
import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { buildWywCompositeSku, c, formatPrice, pickRandomItem, preloadImage } from '../../../utils'
import { LayeredImage } from '../../LayeredImage'
import { SwatchOptionSelect } from "../../deprecated/SwatchOptionSelect";
import styles from './whoop-your-way.module.scss'
import { LinkButton } from '../../LinkButton'
import { ProLogo } from '../../ProLogo'
import { Subtotal, SubtotalLine } from '../../Subtotal'
import { discountPriceByPercent } from '../../../utils/priceUtils'
import { ButtonGroup } from "../../deprecated/ButtonGroup";
import { urlFromMedia } from '../../../utils/mediaUtils'
import {
  WhoopYourWayBase,
  WhoopYourWayConfigurations,
  WhoopYourWayOption,
  WhoopYourWaySelection,
} from '../../../types/Products'
import { useTranslation, importTranslations } from '../../../utils/i18n'
import { AnalyticsFunction, BasicComponentProps } from "../../../types";
import { ProductDetailsGrid } from '../ProductDetailsGrid'
import { AccordionSection } from '../../Accordion'

importTranslations('whoopYourWay', require.context('./languages/', true))

type ComponentName = 'band' | 'clasp' | 'hook' | 'slider';
const ComponentNames: ComponentName[] = ['band', 'clasp', 'hook', 'slider']

interface BandTypeOption {
  value: string,
  label: string,
  description: string[]
}

export type WhoopYourWayProps = {
  configurations: WhoopYourWayConfigurations,
  customizationFee?: number;
  discountPercent?: number;
  whoopProDiscountPercent?: number;
  hasProBenefits?: boolean;
  showAsFree?: boolean;
  value?: WhoopYourWaySelection;
  onChange?: (selection: WhoopYourWaySelection) => void;
  previewClassName?: string;
  infoClassName?: string;
  onAnalyticsEvent?: AnalyticsFunction;
  additionalInfo?: ReactNode | ReactNode[];
  scrollParentRef?: React.RefObject<HTMLElement>;
  accordions?: AccordionSection[];
  noImageScroll?: boolean;
  subTitle?: string;
  description?: string;
  preSelectFilter?: (selection: WhoopYourWaySelection) => boolean;
  imageMessage?: string;
  footer?: React.ReactNode;
  reviewStars?: React.ReactNode;
} & BasicComponentProps;

const getImagesFromSelection = (base: WhoopYourWayBase, selection: WhoopYourWaySelection):
  { frontImages: React.ReactNode, backImages: React.ReactNode } => ({
  frontImages: <>
    <img src={urlFromMedia(base?.front_media)} alt={''} />
    <img src={urlFromMedia(selection?.band?.front_media)} alt={''} />
    <img src={urlFromMedia(selection?.hook?.front_media)} alt={''} />
    <img src={urlFromMedia(selection?.slider?.front_media)} alt={''} />
    <img src={urlFromMedia(selection?.clasp?.front_media)} alt={''} />
  </>,
  backImages: <>
    <img src={urlFromMedia(base?.back_media)} alt={''} />
    <img src={urlFromMedia(selection?.band?.back_media)} alt={''} />
    <img src={urlFromMedia(selection?.slider?.back_media)} alt={''} />
    <img src={urlFromMedia(selection?.clasp?.back_media)} alt={''} />
  </>,
})

const useButtonGroupOptions = (configurations: WhoopYourWayConfigurations) => {
  const { t } = useTranslation('whoopYourWay')
  const descriptions: { [key: string]: string[] } = {
    superknit: [1, 2, 3].map(i => t(`superknit.line${i}`)),
    proknit: [1, 2, 3].map(i => t(`proknit.line${i}`)),
  }
  return useMemo<BandTypeOption[]>((): BandTypeOption[] => {
    const uniqueBandTypes: { [key: string]: BandTypeOption } = {}
    configurations?.band?.options.forEach(({ band_type_group, band_type_text }) => {
      if (band_type_group && !uniqueBandTypes[band_type_group]) {
        uniqueBandTypes[band_type_group] = {
          value: band_type_group,
          label: band_type_text ?? '',
          description: descriptions[band_type_group] || [],
        }
      }
    })
    return Object.values(uniqueBandTypes)
  }, [configurations])
}

export const WhoopYourWay = (
  {
    configurations,
    customizationFee = 0,
    whoopProDiscountPercent = 20,
    discountPercent,
    hasProBenefits,
    showAsFree,
    value,
    onChange,
    className,
    onAnalyticsEvent,
    previewClassName,
    infoClassName,
    additionalInfo,
    scrollParentRef,
    accordions,
    noImageScroll,
    subTitle,
    children,
    description,
    preSelectFilter,
    imageMessage,
    footer,
    reviewStars,
    ...props
  }: WhoopYourWayProps): JSX.Element | null => {
  const { t } = useTranslation('whoopYourWay')
  const [selection, _setSelection] = useState<WhoopYourWaySelection>()
  const bandTypeOptions = useButtonGroupOptions(configurations)
  const [bandType, setBandType] = useState<BandTypeOption>(bandTypeOptions[0])
  const setSelection = (selection: WhoopYourWaySelection) => {
    onChange && onChange(selection)
    // set the band selection based on the currently selected color
    const selectedBandType = bandTypeOptions?.find(({ value }) => value === selection?.band?.band_type_group)
    if (selectedBandType) {
      setBandType(selectedBandType);
    }
    _setSelection(selection)
  }
  const getFullOption = (optionType: ComponentName, sku: string): WhoopYourWayOption | undefined =>
    configurations?.[optionType]?.options?.find(option => option.sku === sku)
  const updateSelection = (componentName: ComponentName, sku: string) => {
    const prevSelection = selection?.[componentName]
    const newSelection = { ...selection }
    newSelection[componentName] = getFullOption(componentName, sku)
    if (newSelection) {
      setSelection(newSelection as WhoopYourWaySelection)
    }
    onAnalyticsEvent && onAnalyticsEvent('WYW Selected Component', {
      component_name: componentName,
      component_sku: sku,
      previous_material: prevSelection?.band_type_group,
      previous_color: prevSelection?.color_group,
      full_sku: buildWywCompositeSku(newSelection as WhoopYourWaySelection),
      // *_group instead of *_text because color text is i18nized and this is for analytics
      material: newSelection?.band?.band_type_group,
      band: newSelection?.band?.color_group,
      clasp: newSelection?.clasp?.color_group,
      hook: newSelection?.hook?.color_group,
      slider: newSelection?.slider?.color_group,
    })
  }
  useEffect(() => {
    if (value) {
      setSelection(value)
    }
  }, [value])

  const pickRandomBandColorByType = (bandType: string) => {
    //iterate through array and and create array with the selected band type
    const bandOptions = configurations?.band?.options.filter((band) =>
      (band.band_type_group === bandType),
    )
    return pickRandomItem(bandOptions)
  }

  const onBandTypeChange = (bandTypeGroup: string) => {
    const bandType: BandTypeOption | undefined = bandTypeOptions.find(({ value }) => value === bandTypeGroup)
    updateSelection('band', pickRandomBandColorByType(bandTypeGroup)?.sku)
    if (bandType) {
      setBandType(bandType)

    }
  }

  const { frontImages, backImages } = getImagesFromSelection(configurations?.base, selection)

  const [showPriceBreakdown, setShowPriceBreakdown] = useState<boolean>(false)
  const togglePriceBreakdown = () => {
    setShowPriceBreakdown(!showPriceBreakdown)
    onAnalyticsEvent && onAnalyticsEvent(`WYW ${!showPriceBreakdown ? 'Opened' : 'Closed'} Price Breakdown`)
  }

  const currency = configurations?.base?.currency
  const bandPrice = selection?.band?.price / 100
  const claspPrice = selection?.clasp?.price / 100
  const hookPrice = selection?.hook?.price / 100
  const sliderPrice = selection?.slider?.price / 100
  const totalPrice = bandPrice + hookPrice + sliderPrice + claspPrice
  const priceMinusFee = bandPrice - customizationFee

  const isProDiscount = hasProBenefits && (!discountPercent || whoopProDiscountPercent >= discountPercent || showAsFree);
  const proDiscountedPrice = formatPrice(
    Math.ceil(discountPriceByPercent(totalPrice, whoopProDiscountPercent)),
    currency,
  );
  const discountedPrice = formatPrice(
    Math.ceil(discountPriceByPercent(totalPrice, discountPercent ?? 0)),
    currency
  )
  const whoopProPrice = showAsFree ? t('free') : proDiscountedPrice;
  const price = isProDiscount ? whoopProPrice :
    !!discountPercent ? formatPrice(discountedPrice, currency) :
    formatPrice(totalPrice, currency);
  const originalPrice = (hasProBenefits || !!discountPercent) && formatPrice(totalPrice, currency);
  const withWhoopProPrice = !hasProBenefits // not pro
    && (!discountPercent || discountPercent < whoopProDiscountPercent) // Pro discount is not worse than current discount
    && whoopProPrice;

  const preloadAllImages = (): void => {
    if (configurations) {
      ComponentNames.forEach(componentName => {
        configurations?.[componentName]?.options
          ?.forEach(({ front_media, back_media }) => {
            preloadImage(urlFromMedia(front_media))
            preloadImage(urlFromMedia(back_media))
          })
      })
    }
  }

  const randomSelection = (): WhoopYourWaySelection => ({
    band: pickRandomItem(configurations?.band?.options?.filter(option => option.quantity > 0)),
    clasp: pickRandomItem(configurations?.clasp?.options?.filter(option => option.quantity > 0)),
    hook: pickRandomItem(configurations?.hook?.options?.filter(option => option.quantity > 0)),
    slider: pickRandomItem(configurations?.slider?.options?.filter(option => option.quantity > 0)),
  })

  const getRandomWYWSelection = () => {
    const iterationLimit = 500
    let iterationCount = 0
    let selection = randomSelection()
    if (preSelectFilter) {
      while(!preSelectFilter(selection) && iterationCount < iterationLimit) {
        selection = randomSelection()
        iterationCount++
      }
    }
    return selection
  }

  useEffect(() => {
    if (configurations) {
      setSelection(getRandomWYWSelection())
      preloadAllImages()
    }
  }, [configurations]);

  useEffect(() => {
    if (preSelectFilter) {
      setSelection(getRandomWYWSelection())
    }
  }, [preSelectFilter])

  const priceBreakdown: SubtotalLine[] = []
  const addPriceBreakdown = (label: string, price: number) => {
    const proDiscountedPrice = formatPrice(discountPriceByPercent(price, whoopProDiscountPercent), currency, undefined, false)
    const discountedPrice = formatPrice(discountPriceByPercent(price, discountPercent ?? 0), currency, undefined, false)
    priceBreakdown.push({
      label: `${label}`,
      price: showAsFree ? t('free') : (
        isProDiscount ? proDiscountedPrice :
        !!discountPercent ? discountedPrice :
        formatPrice(price, currency)
      ),
      originalPrice: (hasProBenefits || !!discountPercent) ? formatPrice(price, currency) : undefined,
      style: { fontWeight: 600 },
    })
  }
  addPriceBreakdown(t('customization'), customizationFee)
  addPriceBreakdown(t('band'), priceMinusFee)
  claspPrice && addPriceBreakdown(t('clasp'), claspPrice)
  hookPrice && addPriceBreakdown(t('hook'), hookPrice)
  sliderPrice && addPriceBreakdown(t('fastLink'), sliderPrice)

  return (
    <ProductDetailsGrid
      media={
        selection && [
          <LayeredImage key='front'>{frontImages}</LayeredImage>,
          <LayeredImage key='back'>{backImages}</LayeredImage>
        ]
      }
      title={configurations?.base?.title}
      price={price}
      className={className}
      previewClassName={previewClassName}
      infoClassName={infoClassName}
      originalPrice={originalPrice ? originalPrice : undefined}
      withWhoopProPrice={withWhoopProPrice ? withWhoopProPrice : undefined}
      isProDiscount={isProDiscount}
      isFancy={true}
      scrollParentRef={scrollParentRef}
      onImageChange={(index) => {
        onAnalyticsEvent && onAnalyticsEvent('WYW Viewed Image', {
          image: index === 0 ? 'Front' : 'Back',
          image_index: index,
        })
      }}
      accordions={accordions}
      noImageScroll={noImageScroll}
      subTitle={subTitle}
      imageMessage={imageMessage}
      description={description}
      footer={footer}
      onAnalyticsEvent={onAnalyticsEvent}
      reviewStars={reviewStars}
      {...props}
    >
      <LinkButton
        className={styles.priceBreakdownButton}
        label={t('priceBreakdown')}
        onClick={togglePriceBreakdown}
        icon={showPriceBreakdown ? 'caret_up' : 'caret_down'}
      />
      {showPriceBreakdown && (
        <div className={c(styles.priceBreakdown)}>
          {hasProBenefits && whoopProDiscountPercent !== 0 && (
            <span className={styles.header}>
              <ProLogo variant='square' />
              <span>
              {t('percentOff', { percent: whoopProDiscountPercent })}
                {showAsFree && ` | ${t('freeEligible')}`}
              </span>
            </span>
          )}
          <Subtotal
            lines={priceBreakdown}
          />
        </div>
      )}
      <div className={styles.productFabricSelection}>
        <h3>
          Material<span className={styles.selectedOption}>{bandType.label}</span>
        </h3>
        <ButtonGroup
          name='fabric-type'
          size='small'
          className={styles.fabricBtn}
          value={bandType.value}
          groupClassName={styles.btnGroup}
          options={bandTypeOptions}
          onChange={onBandTypeChange}
          selectedClassName={''}
        />
        <ul className={styles.fabricDescriptionDetails}>
          {bandType.description.map((item) => (
              <li key={item} className={styles.selectedOption}>{item}</li>
            ),
          )}
        </ul>
      </div>

      {ComponentNames.map(componentName =>
        <React.Fragment key={componentName}>
          <h3 id={componentName}>
            {configurations?.[componentName]?.title}
            <span className={styles.selectedOption}>{
              (selection?.[componentName]?.price && componentName !== 'band') ?
                `${selection?.[componentName]?.color_text} +${formatPrice(selection?.[componentName]?.price / 100, currency)}`
                : selection?.[componentName]?.color_text
            }</span>
          </h3>
          <SwatchOptionSelect
            name={configurations?.[componentName]?.title}
            options={configurations?.[componentName]?.options.filter(({ band_type_group }) =>
              (band_type_group === bandType.value || componentName !== 'band'), bandType)
              .map(({ sku, swatch, color_text, color_group, price, quantity }) => ({
                value: sku,
                label: (price && componentName !== 'band') ? `${color_text} +${formatPrice((price / 100), currency)}` : color_text,
                background: swatch ? `url(${urlFromMedia(swatch)})` : color_group,
                disabled: quantity <= 0,
              }))}
            value={selection?.[componentName]?.sku}
            onChange={sku => updateSelection(componentName, sku)}
            aria-labelledby={componentName}
          />
        </React.Fragment>,
      )}
      {children}
    </ProductDetailsGrid>)
}
