import React, { useState, useEffect } from 'react';
import useForm from 'react-hook-form';
import InputField from './input-field';
import apiService from '../services/api-service';
import styles from '../css/modals.module.css';
import { dataDogLog, gtagCommand } from '../services/monitoring';
import {useTranslation} from "react-i18next";

const LogIn = (props) => {
  const { t } = useTranslation('login');
  const { handleSubmit, register, watch } = useForm();
  const [ error, setError ] = useState(null);
  const [ isDisabled, setIsDisabled ] = useState(true);
  const { closeModal, data, switchModal } = props;
  const { shopifyOrder, zip, stripeOrderId } = data;

  const loginToAccount = (values) => {
    values.orderId = values.orderId && values.orderId[0] === "#" ? values.orderId.substr(1) : values.orderId.toUpperCase();
    values.shopify = shopifyOrder;
    values.currentZip = zip;
    values.stripeId = stripeOrderId;

    apiService.login(values).then(() => {
      closeModal();
      switchModal();
      gtagCommand('Order-Status', 'confirm', 'Modal Log In - Complete');
    }, (error) => {
      setError(true);
      dataDogLog('modalLogIn', errorFormat(values));
    });
  };

  const errorFormat = (input) => {
    return {
      orderId: input.orderId,
      zip: input.orderZip,
      email: input.orderEmail,
      stripeOrderId: input.stripeId,
      shopifyOrder: input.shopify
    }
  }
  
  const handleEnterKey = (e) => {
    const { orderId, orderZip } = watch();

    const values = {
      orderId: orderId,
      orderZip: orderZip
    };

    if (e.key === 'Enter' && !isDisabled) {
      loginToAccount(values);
    }
  };

  useEffect(() => {
    const { orderId, orderZip } = watch();

    if (orderId && orderZip) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [watch]);

  return (
    <div className={ styles.overlay }>
      <div className={ styles.container }>
        <div className={ styles.header }>
          <div className={ styles.title }>{t('confirm')}</div>
        </div>
        <div className={ styles.content }>
          { error ? <div className={ styles.alert }>{t('errorMessage')}</div> : null }
          <InputField type="text" name="orderId" placeholder={t('orderNumber')} inputRef={register} testId="login-order-id" handleEnterKey={handleEnterKey} />
          <div className={ styles.inputField }>
            <InputField type="text" name="orderZip" placeholder={t('zipCode')} inputRef={register} testId="login-order-zip" handleEnterKey={handleEnterKey} />
          </div>
        </div>
        <div className={ styles.buttons }>
          <button className={ styles.btn } onClick={closeModal}>{t('cancel')}</button>
          <button className={ styles.btnGreen } disabled={isDisabled} onClick={handleSubmit(loginToAccount)} data-test-id="login-save">{t('save')}</button>
        </div>
      </div>
    </div>
  );
};

export default LogIn;
