import React from 'react';
import { handleize } from '../../utils';
import * as PropTypes from 'prop-types';
const allSvgs = require.context('../../icons', true, /\.svg$/);
const allPaths = allSvgs.keys();
const ICON_PATH_REGEX = /\/(ic_)?([A-z0-9_ -]*)(\.color)?\.svg$/;
const iconMap = {};

export const __iconGroups__ = {}; // to be used for storybook purposes only

allPaths.forEach(path => {
  const match = path.match(ICON_PATH_REGEX);
  if (match && match[2]) {
    const iconName = handleize(match[2]).replace(/-/g, '_');
    iconMap[iconName] = allSvgs(path)?.default;
    let lastIconGroup;
    const iconGroupPath = path.split('/').filter(dir => !dir.includes('.'));
    iconGroupPath.forEach(group => {
      if (!__iconGroups__[group]) {
        __iconGroups__[group] = [];
      }
      lastIconGroup = __iconGroups__[group];
    });
    lastIconGroup.push(iconName);
  }
});

export const iconList = Object.keys(iconMap);

export const Icon = ({ className, name = 'strap', ...props }) => {
  const icon = iconMap[name];
  return icon
    ? React.createElement(icon,
      {
        ...props,
        className
      },
      null) : null;
};

Icon.propTypes = {
  /**
   * Icon name
   */
  name: PropTypes.oneOf(iconList),
};

