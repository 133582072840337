import React, { useState, useEffect } from 'react';
import { Alert, ReferAFriend } from '@whoop/web-components';
import OrderDetails from './order-details';
import ProductInfo from './product-info';
import UpdatePaymentMethod from './update-payment-method';
import { hasRegionWithNoZipCodeCustomer, getJoinFlowUrl } from '../services/support';
import { segmentIdentify, segmentTrack, gaCommand } from '../services/monitoring';
import styles from '../css/reservation-info.module.css';
import { useTranslation, Trans } from "react-i18next";

const ReservationInfo = ({ order }) => {
  const { t } = useTranslation('reservationInfo');
  const [isInPaymentFailedState, setIsInPaymentFailedState] = useState(order.reservationState === 'payment_failed');
  const [isPaymentUpdated, setIsPaymentUpdated] = useState(false);
  let products = [];

  const pageTypes = {
    'paid': 'Update successful',
    'payment_failed': 'Update needed',
    'canceled': 'Out of date range'
  };

  useEffect(() => {
    const userId = order.userId || order.stripeOrderId;

    segmentIdentify(userId);
    segmentTrack('Reservation Payment Page', {
      category: 'Viewed Page',
      orderId: order.stripeOrderId,
      pageType: pageTypes[order.reservationState]
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (order.hasProducts) {
    products = order.products
      .sort((a, b) => {
        return (a.priority > b.priority) ? 1 : -1;
      })
      .map((item, index) => {
        return <ProductInfo key={index} index={index} product={item} taxRate={order.taxRate} currencyType={order.currencyType} />
      });
  }

  const handleUpdatePaymentSuccess = () => {
    setIsInPaymentFailedState(false);
    setIsPaymentUpdated(true);
  };

  const handleShareClick = () => {
    gaCommand('send', ['event', 'order_status', 'click', 'Share Referral']);

    segmentTrack('Refer Friend- Invite Friend', {
      category: 'Referral',
      label: 'order_status',
    });
  }

  const getOrderStatusUrl = () => {
    let url = `/?stripe=${order.stripeOrderId}`;

    if (hasRegionWithNoZipCodeCustomer()) {
      return url + `&email=${order.email}`;
    }

    return url + `&zip=${order.zip}`;
  }

  if (order.reservationState === 'canceled') {
    return (
      <div className={styles.container}>
        <div className={styles.orderCanceled}>
          <span>
            <Trans t={t} i18nKey="unableToProcessPayment">
              We were unable to process your payment for your WHOOP 4.0. Please use the
              <a className={styles.link} href={getJoinFlowUrl()}>link</a>
              to place another order.
            </Trans>
          </span>
        </div>
      </div>
    );
  }

  const shippingData = {
    city: order.city,
    state: order.state,
    zip: order.zip,
    address1: order.address1,
    address2: order.address2,
    country: order.country,
    hasAddressLine2: order.address2 && order.address2.length > 0
  };

  return (
    <div className={`${styles.container} ${isInPaymentFailedState ? styles.updatePaymentBackground : null}`}>
      {isInPaymentFailedState && (<div className={styles.title}>{t('whoopReserved')}</div>)}
      {isPaymentUpdated && (<div className={styles.title}>{t('paymentUpdated')}</div>)}
      <div className={styles.orderDetails}>
        {(isPaymentUpdated || order.reservationState === 'paid') && (
          <>
            <div className={styles.cardHeader}>
              {order.reservationState === 'paid' && (<span className={styles.orderStatusText}>{t('reservationPaidSuccessfully')}&nbsp;</span>)}
              <span className={styles.orderStatusText}>
                <Trans t={t} i18nKey="checkOrderStatus">
                  Check <a className={styles.link} href={getOrderStatusUrl()}>order status</a> to see your estimated delivery.
                </Trans>
              </span>
            </div>
            <hr />
          </>
        )}
        <OrderDetails order={order} shippingData={shippingData} products={products} />
      </div>
      {isInPaymentFailedState && (
        <>
          <div className={styles.alertWrapper}>
            <Alert type="error">
              {t('updatePaymentMethodBefore', {date: order.updatePaymentByDate})}
            </Alert>
          </div>
          <div className={styles.paymentWrapper}>
            <UpdatePaymentMethod order={order} onSubmitSuccess={handleUpdatePaymentSuccess} />
          </div>
        </>
      )}
      {(isPaymentUpdated || order.reservationState === 'paid') && order.referral && order.referral.code && (
        <ReferAFriend referralMessage={order.referral.message} onShareClick={handleShareClick} className={styles.referral} />
      )}
    </div>
  );
};

export default ReservationInfo;