export type PaymentMethod =
  'amex'        |
  'apple_pay'   |
  'diners_club' |
  'discover'    | 
  'google_pay'  |
  'mastercard'  |
  'shop_pay'    |
  'visa'

export const DefaultPaymentMethods: PaymentMethod[] = [
  'amex',
  'mastercard',
  'visa',
  'discover',
  'diners_club'
];