import React from "react";
import s from './product-header.module.scss';
import { c } from "../../../utils";
import { Price } from '../../Price';
import { ProLogo } from '../../ProLogo';
import { useTranslation, importTranslations } from "../../../utils/i18n";
importTranslations('productHeader', require.context('./languages/', true));

export interface ProductHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  price: string;
  originalPrice?: string;
  isProDiscount?: boolean;
  withWhoopProPrice?: string;
  fancy?: boolean;
  size?: HeaderSize;
  subTitle?: string;
  subClassName?: string;
  reviewStars?: React.ReactNode;
}

export type HeaderSize = 'small' | 'medium' | 'large';

/**
 * Product Header component. Used as a header for any product detail
 * page or product detail modal. It supports showing a price and
 * discount price, as well as showing potential price "with WHOOP Pro".
 * These configurations are all possible based on what properties are provided.
 *
 * For instance, providing a `price` & `originalPrice` will show the price as
 * discounted, but if you pass `isProDiscount={true}` then it will show the
 * price as a WHOOP Pro discounted price.
 *
 * For this reason, users of this component need to be careful of what prices
 * to pass based on the users WHOOP Pro state.
 *
 * Prices are just strings, so users must also format prices properly.
 *
 * @param title product title. Will be uppercase.
 * @param price formatted price string
 * @param originalPrice crossed out price or original price if WHOOP Pro
 * @param isProDiscount if the price is a WHOOP Pro price
 * @param withWhoopProPrice CTA "$X with PRO" price (will not show if isProDiscount)
 * @param className
 * @param size
 * @param subTitle
 * @param fancy sets a color gradient on the text
 * @param subClassName
 * @param props
 */
export const ProductHeader = ({
  title,
  price,
  originalPrice,
  withWhoopProPrice,
  isProDiscount,
  className,
  size='large',
  subTitle,
  fancy,
  subClassName,
  reviewStars,
  ...props
}: ProductHeaderProps): JSX.Element => {
  const { t } = useTranslation('productHeader');
  return <div className={c(s.productHeader, size && s[`header-${size}`], 'whoop-ui', className)}
              {...props}>
    <div className={s.headerGroup}>
      <h1 className={c(fancy && s.fancy)}>{title}</h1>
    </div>
    {price && <div className={c(s.priceContainer) }>
      <Price
        className={c(s.price)}
        price={price}
        originalPrice={originalPrice}
        isProDiscount={isProDiscount}
      />
      {subTitle && <h3 className={c(s.subTitle, subClassName)}>{subTitle}</h3>}
      {!isProDiscount && withWhoopProPrice && (
        <div className={c(s.withWhoopProPrice)} data-test-id='with-pro-price'>
          <span>{withWhoopProPrice} {t('with')}</span>
          <span data-tooltip={t('saveWithWhoopPro')}><ProLogo variant='square'/></span>
        </div>
      )}
    </div>}
    {reviewStars}
  </div>
};
