export enum DropdownThemes {
  DEFAULT = 'default',
  DARK = 'dark',
  CURRENCY = 'currency',
  CURRENCY_DARK = 'currency-dark'
}

export type DropdownOption = {
  value: string
  label: string
}
