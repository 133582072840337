import React, { useEffect, useState } from 'react';
import { Button } from '../Button';
import { Input } from '../Input';
import '../../index.module.scss';
import styles from './promo-code.module.scss';
import { importTranslations, useTranslation } from '../../utils/i18n';
import { FormInputType } from '../../types/FormInputType';
import { ButtonVariants } from '../../types/Buttons';
import { PromoCodeThemes } from '../../types/PromoCodeThemes';
import { classes } from '../../utils';

importTranslations('promoCode', require.context('./languages/', true));

export interface PromoCodeProps {
  applyButtonStyle?: React.CSSProperties,
  codeSubmitted: boolean,
  isCodeValid: boolean,
  isOpen?: boolean,
  onClickToggle?: () => void,
  submitCode: (promoCode: string) => void,
  successMessage?: string,
  theme?: PromoCodeThemes,
  value?: string;
  buttonLabel?: string;
}

export const PromoCode = ({
  applyButtonStyle,
  codeSubmitted,
  isCodeValid,
  isOpen = false,
  onClickToggle,
  submitCode,
  successMessage,
  theme,
  value = '',
  buttonLabel,
  ...props
}: PromoCodeProps) => {
  const { t } = useTranslation('promoCode');
  const [promoCode, setPromoCode] = useState(value);
  const [showContent, setShowContent] = useState(isOpen);

  // set value when parent updates value
  useEffect(() => setPromoCode(value), [value]);

  const handleToggleClick = () => {
    setShowContent(!showContent);
    if (onClickToggle) onClickToggle();
  };

  const getSubText = () => {
    if (!codeSubmitted || !promoCode?.length) return '';
    if (isCodeValid && successMessage) return successMessage; 
    if (isCodeValid) return t('codeApplied');
    return t('invalidCode');
  };

  const getSubTextStyle = () => {
    if (isCodeValid) {
      const color = theme === PromoCodeThemes.DARK ? '#19EC06' : 'green';
      return { color };
    }
    return {};
  };

  return (
    <div className={classes(theme && `theme-${theme}`)} {...props}>
      <button type='button' className={styles.contentToggle} onClick={handleToggleClick}>{buttonLabel ?? t('haveAPromoCode')}</button>
      {showContent &&
        <div className={styles.content}>
          <Input
            label=''
            value={promoCode}
            type={FormInputType.TEXT}
            placeholder={t('enterPromoCode')}
            subText={getSubText()}
            subTextStyle={getSubTextStyle()}
            error={codeSubmitted && !isCodeValid && promoCode?.length > 0}
            onChange={setPromoCode}
          />
          <Button
            className={styles.applyBtn}
            label={t('apply')}
            variant={ButtonVariants.SECONDARY}
            disabled={promoCode?.length === 0}
            style={applyButtonStyle}
            onClick={() => submitCode(promoCode)}
          />
        </div>}
    </div>
  );
};
