const SMARTY_BASE_URL = 'https://us-autocomplete-pro.api.smartystreets.com/lookup';

export const searchAddresses = (apiKey: String, searchText: String) => {
    const requestUrl = `${SMARTY_BASE_URL}?key=${apiKey}&search=${searchText}`;
    return fetch(requestUrl)
      .then(res => res.json())
      .catch((e) => {
        console.error(e);
        return [];
      });
};

export const searchAddressesWithSelection = async (apiKey: String, searchText: String, selectedAddress: String) => {
    const requestUrl = `${SMARTY_BASE_URL}?key=${apiKey}&search=${searchText}&selected=${selectedAddress}`;
    return fetch(requestUrl)
      .then(res => res.json())
      .catch((e) => {
        console.error(e);
        return [];
      });
};
