import React, { useState, useEffect } from 'react';
import styles from '../css/shipping-info.module.css';
import ProductInfo from './product-info';
import OrderDetails from './order-details';
import DeliveryNotice from './delivery-notice';
import PartialShipments from './partial-shipments';
import { Alert, GiftMessageSection, Icon, ReferAFriend } from '@whoop/web-components';
import { logAmplitudeEvent, gaCommand, segmentTrack } from '../services/monitoring';
import GiftIcon from '../assets/gift-icon-white.svg';
import _ from 'lodash';
import { useTranslation, Trans } from "react-i18next";
import i18n from 'i18next';
import { getRegionFromRequest } from '@whoop/i18n';

export const isDeepLink = (orderId, userZip, isError) => {
  const params = ['order', 'zip'];
  const userValues = {
    order: orderId,
    zip: userZip
  }
  const urlSearchParams = new URLSearchParams(window.location.search);

  if (isError) {
    return params.every((param) => urlSearchParams.has(param)) && params.some((param) => urlSearchParams.get(param) !== userValues[param]);
  } else {
    return params.every((param) => urlSearchParams.has(param) && urlSearchParams.get(param) === userValues[param]);
  }
}

const ShippingInfo = (props) => {
  const { orderId, userZip, orderData, changeUserAddress, shippingData, cancelUserOrder, resetValues } = props;
  const referral = orderData.referral;

  const { t } = useTranslation('shippingInfo');
  const [ isGiftView, setIsGiftView ] = useState(false);
  const [ orderType, setOrderType ] = useState('');
  const [ data, setData ] = useState({});
  const [ giftId, setGiftId ] = useState('');
  const [ giftMessage, setGiftMessage ] = useState(null);

  let products = null;

  const orderTypes = {
    join: 'join',
    upgrade: 'upgrade',
    gift: 'gift',
    other: 'other'
  };

  const getOrderType = (order = {}) => {
    if (order.isUpgrade) {
      return orderTypes.upgrade;
    }
    if (order.isGift) {
      return orderTypes.gift;
    }
    if (order.stripeOrderId) {
      return orderTypes.join;
    }
    return orderTypes.other;
  };

  const sortProducts = (data) => {
    if (!data.products) {
      return data;
    }
    data.products = data.products
      ? data.products
          .sort((a, b) => {
            return (a.priority > b.priority) ? 1 : -1;
          })
      : {};
    return data;
  };

  useEffect(() => {
    const _orderType = getOrderType(orderData);
    setOrderType(_orderType);
    const _data = sortProducts(orderData);
    setData(_data);
    setGiftId(_data.giftId);
    setIsGiftView(_data.giftView);
    setGiftMessage(_data.giftMessage);
    const userId = _data.userId || _data.stripeOrderId;
    if (userId && orderType) {
      logAmplitudeEvent('Viewed Order Status Page', {
        category: 'Order Status',
        region: getRegionFromRequest(shippingData.country),
        orderType: orderType,
        orderId: _data.stripeOrderId,
        giftView: _data.giftView,
        has_order_entered: isDeepLink(orderId, userZip, false) ? 'deeplink' : 'search'
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderData, orderType]);

  if (data.hasProducts) {
    products = data.products
      .map((item, index) => {
        return <ProductInfo key={index} index={index} product={item} taxRate={data.taxRate} currencyType={data.currencyType} giftView={isGiftView} />
      });
  }

  const goToGiftReceipt = () => {
    window.open(`/?gift=${giftId}`, '_blank');
  };

  const handleShareClick = () => {
    gaCommand('send', ['event', 'order_status', 'click', 'Share Referral']);

    segmentTrack('Refer Friend- Invite Friend', {
      category: 'Referral',
      label: 'order_status',
      region: getRegionFromRequest(shippingData.country),
    });
  }

  const shippingLabel = _.get(data, 'shippingEstimate.label');
  const shippingEstimate = _.get(data, 'shippingEstimate.estimate');
  const shippingDisclaimer = _.get(data, 'shippingEstimate.disclaimer');
  const subtext = _.get(data, 'shippingEstimate.subtext');
  const trackingNumber = _.get(data, 'shippingEstimate.trackingNumber');
  const trackingLink = _.get(data, 'shippingEstimate.trackingLink');
  const isPartialShipment = data.stageName === 'Partial-Shipment';
  
  const showHighVolumeShippingDisclaimer = !isGiftView && !data.canceled && !data.isShipped && false; // ['AUD', 'CAD', 'EUR', 'GBP'].includes(_.get(data, 'currencyType'));

  return (
    <span>
      { !isGiftView && shippingDisclaimer && <div dangerouslySetInnerHTML={{ __html: shippingDisclaimer }} className={ styles.shippingDisclaimer }></div> }
      { showHighVolumeShippingDisclaimer &&
        <Alert type='info' className={styles.shippingDisclaimerAlert}>
          <span className={styles.shippingDisclaimerAlertCopy}>{t('highVolumeShippingDelay')}</span>
        </Alert>
      }
      {isGiftView && <div className={styles.giftReceiptTitle}>{t('giftReceipt')}</div>}
      { isGiftView && giftMessage &&
        <div className={styles.recipientGiftMessage}>
          <GiftMessageSection
            canEdit={false}
            gifteeView={true}
            giftMessage={giftMessage}
            openGiftMessageModal={() => {}}
            purchaserEmail={giftMessage.gifter_email} />
        </div>
      }
      { !isGiftView && giftMessage &&
        <div className={styles.recipientGiftMessage}>
          <GiftMessageSection
            canEdit={false}
            gifteeView={false}
            giftMessage={giftMessage}
            giftReceiptUrl={`/?gift=${data.giftId}`}
            openGiftMessageModal={() => {}}
            purchaserEmail={data.email} />
        </div>
      }
      { giftId && !isGiftView && !giftMessage &&
        <div className={styles.giftMessageAlert} onClick={() => goToGiftReceipt()}>
          <div className={styles.icon}><img src={GiftIcon} alt=''/></div>
          <div>
            <div className={styles.alertTitle}>{t('sendGiftReceipt')}</div>
            <div className={styles.alertBody}>
              <Trans t={t} i18nKey="viewGiftReceiptToSend">
                View the <span className={styles.link}>gift receipt</span> to send to your gift recipient
              </Trans>
            </div>
          </div>
        </div>
      }
      <div className={ styles.shippingInfo }>
        <div className={ styles.shipContainer }>
          <div className={ styles.cardHeader }>
            { shippingLabel && (
              <div className={ styles.shippingEstimate }>
                <span className={styles.shippingLabel }>{ shippingLabel }</span>
                <span className={styles.shippingDate }>{ shippingEstimate }</span>
              </div>
            )}
            { isPartialShipment
              ? <PartialShipments orderItems={ data.products } />
              : (
                  <div className={ styles.estimationDisclaimer }>
                    {
                      (trackingNumber && trackingLink)
                      ? (
                          <Trans t={t} i18nKey="trackYourOrder" trackingNumber={trackingNumber}>
                            Track your order: <a href={trackingLink} target="_blank" rel="noopener noreferrer">{{ trackingNumber }}</a>
                          </Trans>
                        )
                        : trackingNumber || subtext
                    }
                  </div>
                )
            }
          </div>
          {shippingData.country === "IN" && <DeliveryNotice/>}
          <hr />
          {data.hasProducts ? (
            <OrderDetails
              order={data}
              shippingData={shippingData}
              products={products}
              showUpdateLinks
              onChangeUserAddress={changeUserAddress}
              onCancelOrder={cancelUserOrder}
              onViewAnotherOrder={resetValues}
            />
          ) : null}
        </div>
      </div>
      { isGiftView &&
        <>
          <div className={styles.shopSection}>
            <div className={styles.shopIcon}></div>
            <div>
            <a href="https://shop.whoop.com" target="_blank" rel="noopener noreferrer" className={styles.shopTitle}>{t('shopWhoopAccessories')}</a>
            </div>
          </div>
          <div className={styles.learnMore}>
            <div className={styles.learnIcon}>
              <Icon name="logo_whoop_circle"></Icon>
              </div>
            <div className={styles.learnTitle}>{t('learnMore')}</div>
            <div className={styles.learnLinks}>
            <a href="https://www.whoop.com/thelocker/" target="_blank" rel="noopener noreferrer">{t('headToLocker')}</a>
              <a href="https://www.whoop.com/thelocker/whoop-podcast-all-episodes/" target="_blank" rel="noopener noreferrer">{t('checkoutPodcast')}</a>
            </div>
          </div>
        </>
      }
      {referral && referral.code && i18n.resolvedLanguage === 'en' && (
        <ReferAFriend
          referralMessage={referral.message}
          onShareClick={handleShareClick}
          className={styles.referral}
        />
      )}
    </span>
  );
}

export default ShippingInfo;
