import React from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import LookUpReservation from './look-up-reservation';
import config from '../config';

const stripe = loadStripe(config.stripeKey);

const ReservationStatus = () => {
  const urlString = window.location.href.toString();
  const isRegionWithNoZipCode = urlString.indexOf('email=') > -1;
  let regionWithNoZipCodeUrl;

  if (isRegionWithNoZipCode) {
    const email = urlString.split('email=');
    regionWithNoZipCodeUrl = `${email[0]}email=${encodeURIComponent(email[1])}`;
  }

  const url = new URL(isRegionWithNoZipCode ? regionWithNoZipCodeUrl : urlString);

  const props = {
    stripeOrder: url.searchParams.get("stripe"),
    orderZip: url.searchParams.get("zip"),
    orderEmail: url.searchParams.get("email")
  };

  const options = {
    fonts: [{
      cssSrc: "https://fonts.whoop.com/fonts.css"
    }]
  };

  return (
    <Elements stripe={stripe} options={options}>
      <LookUpReservation {...props} />
    </Elements>
  )
};

export default ReservationStatus;
