import React from 'react';
import {useTranslation} from "react-i18next";
import { getProvincesForCountry } from '@whoop/i18n';

const StateSelection = (props) => {
  const { t } = useTranslation('updateUserAddress');
  const { country, name, inputRef } = props;

  const stateList = country
    ? getProvincesForCountry(country)
    : [];

  return (
    <select data-test-id="state-select" name={name} ref={inputRef}>
      <option value="" name="">{t('state')}</option>
      {
        stateList.map((states, index) => (
          <option key={index} value={states.code} name={states.name}>{states.name}</option>
        ))
      }
    </select>
  );
};

export default StateSelection;
