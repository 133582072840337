import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { Subtotal } from '@whoop/web-components';
import styles from '../css/order-details.module.css';
import {
  formatDate,
  getTaxRegistrationNumber,
  hasRegionWithNoZipCodeCustomer,
  showTaxRegistration,
  useSubtotalLines
} from '../services/support';
import config from '../config';
import {useTranslation} from "react-i18next";

const OrderDetails = ({
  order,
  shippingData,
  products,
  showUpdateLinks,
  onChangeUserAddress,
  onCancelOrder,
  onViewAnotherOrder
}) => {
  const { t } = useTranslation('orderDetails');
  const [showProducts, setShowProducts] = useState(false);
  const isPending = order.lookupType === 'checkout';
  const notShippedAndHasDate = !order.isShipped && order.stageName === 'Waiting for fulfillment';
  const isShopifyOnly = order.shopifyOrder && !order.stripeOrderId && !order.orderCharge;
  const isAvailable = order.hasAccess;
  const isGiftView = order.giftView;
  const subtotalLines = useSubtotalLines(order);
  const showRegistrationDetails = showTaxRegistration(order);
  const taxRegistrationNumber = getTaxRegistrationNumber(order);

  useEffect(() => {
    if (isGiftView || !order.referral) {
      setShowProducts(true);
    }
  }, [isGiftView, order]);

  const onViewOrderDetails = e => {
    e.preventDefault();
    setShowProducts(!showProducts);
  }

  return (
    <div className={ styles.cardBody }>
      <div className={ styles.viewOrder }>
        <a href="/" onClick={onViewOrderDetails} data-test-id="view-order-details">
          <i className={ styles.down } style={{display: !showProducts ? 'inline-block' : 'none'}}></i>
          <i className={ styles.up } style={{display: showProducts ? 'inline-block' : 'none'}}></i>
          &nbsp; {hasRegionWithNoZipCodeCustomer() ? t('viewReceipt') : t('viewOrderDetails')}
        </a>
      </div>
      { showProducts && (
        <div className={ styles.cart }>
          <div className={styles.regionWithNoZipCodeContainer} style={{display: showRegistrationDetails ? 'flex' : 'none'}}>
            <div>
              <span className={styles.title}>{t('companyAddress')}</span><br />
              WHOOP<br />
              One Kenmore Square<br />
              Suite 601<br />
              Boston, MA 02215 United States<br />
            </div>
            <div className={styles.regionWithNoZipCodeDetails}>
              <div>
                <span className={styles.title}>{t('date')}</span><br />
                {formatDate(order.closeDate)}
              </div>
              {Boolean(taxRegistrationNumber)
                ? (
                  <p>
                    <span className={styles.title}>{t('taxRegistration')}</span><br />
                    {taxRegistrationNumber}
                  </p>
                )
                : null
              }
            </div>
          </div>
          <div className={ `${styles.topHalf} ${isGiftView ? styles.giftReceiptTopHalf : ''}` }>
            <div className={ styles.shipInfo1 }>
              <div className={ styles.title }>{t('shippingTo')}</div>
              <div>{order.firstName} {order.lastName}</div>
              <div>{shippingData.address1}</div>
              {shippingData.hasAddressLine2 && <div>{shippingData.address2}</div>}
              <div>{shippingData.city}, {shippingData.state} {!hasRegionWithNoZipCodeCustomer() ? shippingData.zip : null} {shippingData.country}</div>
              {showUpdateLinks && !isGiftView && (
                <div className={styles.updateLinks}>
                  {/*Removed for ERP launch*/}
                  {/*{notShippedAndHasDate && (*/}
                  {/*  <div>*/}
                  {/*    <a href="/" onClick={onChangeUserAddress} data-test-id="update-shipping">&rarr; {t('updateShippingAddress')}</a>*/}
                  {/*  </div>*/}
                  {/*)}*/}
                  {/*{(!isPending && notShippedAndHasDate && !isShopifyOnly && !order.isUpgrade) && (*/}
                  {/*  <div>*/}
                  {/*    <a href="/" onClick={onCancelOrder}>&rarr; {t('cancelOrder')}</a>*/}
                  {/*  </div>*/}
                  {/*)}*/}
                  <div>
                    <a href="/" onClick={onViewAnotherOrder}>&rarr; {t('viewAnotherOrder')}</a>
                  </div>
                </div>
              )}
            </div>
            <div className={ styles.shipInfo2 }>
              {(isAvailable || isPending) && (
                <div className={ styles.shipItem }>
                  <div className={ styles.title }>{t('orderNumber')}</div>
                  { !isPending ? order.orderId : t('pending') }
                </div>
              )}
              {!order.isGift && (
                <div className={ styles.shipItem }>
                  <div className={ styles.title }>{t('email')}</div>
                  {order.email}
                </div>
              )}
            </div>
          </div>
          <div className={ styles.receiptContainer }>
            { products }
            {!isGiftView && (
              <div className={styles.subtotalContainer}>
                <Subtotal lines={subtotalLines} />
              </div>
            )}
            {!isGiftView && order.isReservation && order.reservationState === 'reserved' && (
              <div className={styles.reservationCallout}>{t('youWontBeChargedDisclaimer')}</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

OrderDetails.propTypes = {
  order: PropTypes.object.isRequired,
  shippingData: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  showUpdateLinks: PropTypes.bool,
  onChangeUserAddress: PropTypes.func,
  onCancelOrder: PropTypes.func,
  onViewAnotherOrder: PropTypes.func
};

OrderDetails.defaultProps = {
  showUpdateLinks: false,
  onChangeUserAddress: () => {},
  onCancelOrder: () => {},
  onViewAnotherOrder: () => {}
};

export default OrderDetails;
