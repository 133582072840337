import React, { useState } from 'react'
import { Button } from '../../Button'
import {
  ButtonSizes,
  ButtonThemes,
  ButtonVariants
} from '../../../types/Buttons'
import { Form } from '../../Form'
import { importTranslations, useTranslation } from '../../../utils/i18n'
import { RadioInput } from '../../RadioSelectors/RadioInput'
import styles from './shippingMethodForm.module.scss'

importTranslations(
  'shipping-method-form',
  require.context('./languages/', true)
)

export type ShippingOption = {
  id: string
  name: string
  description?: string
  display_price: string
  subtotal: number
  tax?: number
}

export interface ShippingMethodFormProps
  extends React.HTMLAttributes<HTMLDivElement> {
  shippingOptions: ShippingOption[]
  onSubmit: (shippingOption: any) => void
  onSelectionChange: (shippingOption: any) => void
}

export function ShippingMethodForm({
  shippingOptions,
  onSubmit,
  onSelectionChange,
  className,
  ...props
}: ShippingMethodFormProps) {
  const { t } = useTranslation('shipping-method-form')
  const [selectedShippingOption, setSelectedShippingOption] = useState<string>(
    shippingOptions[0].id
  )

  const onChange = (value: string) => {
    setSelectedShippingOption(value)
    onSelectionChange(value)
  }

  return (
    <Form
      onSubmit={() => onSubmit(selectedShippingOption)}
      {...props}
      className={className}
    >
      <RadioInput
        className={styles.shippingOptions}
        value={selectedShippingOption}
        name='shipping-options'
        isShippingMethodRadioInput
        onChange={onChange}
        options={shippingOptions.map(
          ({ id, name, description, display_price }) => {
            return {
              value: id,
              label: name,
              sublabel: description,
              price: display_price
            }
          }
        )}
      />
      <Button
        className={styles.nextButton}
        type='submit'
        label={t('nextButton')}
        variant={ButtonVariants.PRIMARY}
        size={ButtonSizes.LARGE}
        theme={ButtonThemes.JOIN_FLOW}
        disabled={!selectedShippingOption}
      />
    </Form>
  )
}
