import { Language, DEFAULT_LANGUAGE } from '@whoop/i18n';

export const BASE_WHOOP_URL = 'https://whoop.com';
export const PRIVACY_POLICY_URL = `${BASE_WHOOP_URL}/privacy`;
export const TERMS_OF_SALE_URL = `${BASE_WHOOP_URL}/termsofsale`;
export const TERMS_OF_USE_URL = `${BASE_WHOOP_URL}/termsofuse`;
export const RETURN_POLICY_URL =
  'https://api.prod.whoop.com/url-mapping-service/v1/RETURN_POLICY';

export const getJoinUrl = (isProd: boolean = true) => {
  return `https://join${isProd ? '' : '.qa'}.whoop.com`;
};

export const getShopUrl = (isProd: boolean = true) => {
  return `https://shop${isProd ? '' : '.qa'}.whoop.com`;
};

export const getSupportUrl = (language: Language = DEFAULT_LANGUAGE) => {
  const languageModifier = language === DEFAULT_LANGUAGE ? '' : `${language}.`;
  return `https://${languageModifier}support.whoop.com`;
};
