import React from 'react';
import styles from './checkbox.module.scss';
import '../../index.module.scss';
import * as PropTypes from 'prop-types';
import { c } from '../../utils';

/**
 * Checkbox component
 * pass in state and callback to change state/handle click
 */
export const Checkbox = React.forwardRef(
  (
    { className, id, checked = false, onClick, size = 'small', shape = 'square', disabled = false, style, ...props },
    ref
  ) => {
    const onClickHandler = (event) => {
      if (!disabled) {
        return onClick && onClick(event);
      }
    };
    return (
      <div
        className={c(
          className,
          styles.checkbox,
          styles[`checkbox-${size}`],
          styles[`checkbox-${shape}`],
          disabled && styles.disabled
        )}
        style={style}
      >
        <input
          type='checkbox'
          id={id}
          defaultChecked={checked}
          disabled={disabled}
          onClick={onClickHandler}
          ref={ref}
          {...props}
        />
        <label htmlFor={id} />
      </div>
    );
  }
);

Checkbox.propTypes = {
  /**
   * Checkbox checked
   */
  checked: PropTypes.bool,
  /**
   * Checkbox disabled
   */
  disabled: PropTypes.bool,
  /**
   * Checkbox onClick
   */
  onClick: PropTypes.func,
  /**
   * Checkbox size
   */
  size: PropTypes.oneOf(['small', 'large']),
  /**
   * Checkbox shape
   */
  shape: PropTypes.oneOf(['round', 'square']),
  /**
   * Checkbox id: required and cannot conflict with other ids in the DOM
   */
  id: PropTypes.string.isRequired
};
