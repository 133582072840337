import React from "react";
import styles from "./cart-alert.module.scss";
import {classes} from "../../../utils";
import {LearnMore} from "../../LearnMore/LearnMore";

export interface CartAlertProps extends React.HTMLAttributes<HTMLDivElement> {
  message: string;
  learnMoreAction: () => void;
}

export function CartAlert({ message, learnMoreAction, className, ...props }: CartAlertProps) {

  return (
    <div className={classes(className, styles.wrapper)} {...props}>
      <p>
        {message}
        {learnMoreAction ? <LearnMore onLearnMore={learnMoreAction}/> : null}
      </p>
    </div>
  );
}
