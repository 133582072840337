import React from 'react';
import { useController, useFormContext, Validate } from 'react-hook-form';
import { Input } from '../Input';
import { FormInputType } from '../../types/FormInputType';
import { BasicComponentProps } from "../../types";

export type FormInputProps = {
  label: string;
  name: string;
  placeholder?: string;
  type: FormInputType;
  defaultValue?: string;
  required?: {
    value: boolean;
    message: string;
  };
  validate?: Validate<string> | Record<string, Validate<string>>;
  pattern?: {
    value: RegExp;
    message: string;
  };
  disabled?: boolean;
  minLength?: {
    value: number;
    message: string;
  };
  maxLength?: {
    value: number;
    message: string;
  };
  subText?: string;
} & BasicComponentProps;

export const FormInput = ({
  name,
  minLength,
  maxLength,
  required,
  validate,
  pattern,
  defaultValue,
  subText,
  ...props
}: FormInputProps) => {
  const { control } = useFormContext();

  const {
    field: { onChange, onBlur, name: fieldName, value, ref },
    fieldState: { invalid, error }
  } = useController({
    name,
    control,
    rules: { required, minLength, maxLength, validate, pattern },
    defaultValue,
  });

  return (
    <Input
      useExternalState
      name={fieldName}
      value={value}
      required={required && required.value}
      error={invalid}
      onChange={onChange}
      onBlur={onBlur}
      inputRef={ref}
      subText={error ? error.message : subText}
      {...props}
    />
  );
};
