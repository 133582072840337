import { Accordion, AccordionSection } from '../../Accordion'
import React from 'react'
import PropTypes from 'prop-types'
import styles from './whoop-pro-modal.module.scss'
import { ProLogo } from '../../ProLogo'
import { Icon } from '../../Icon'
import { Button } from '../../Button'
import { ButtonSizes, ButtonVariants } from '../../../types/Buttons'
import { classes } from '../../../utils'
import { importTranslations, useTranslation } from '../../../utils/i18n'
import { Modal } from '../../Modal/modal'
import { Alert } from '../../Alert'
import { Alerts } from '../../../types/Alerts'
import { StatusTagThemes } from '../../../types/StatusTagThemes'
import { AnalyticsFunction } from 'types'
import { Language } from '@whoop/i18n'

importTranslations('whoopProModal', require.context('./languages/', true))

type Perks = {
  description: string
  icon: {
    name: string
  }
}
export interface WhoopProModalProps
  extends React.HTMLAttributes<HTMLDivElement> {
  header: string
  subHeader?: string
  perks?: Perks[]
  actionLabel?: string
  onAction?: Function
  subTitle?: String
  mainTitle?: string
  accordion?: AccordionSection[]
  show?: boolean
  onSignInClick?: Function
  supportUrl?: string
  onClose?: Function
  onAnalyticsEvent?: AnalyticsFunction
  showAlert?: boolean
  language?: Language
}

export function WhoopProModal({
  header,
  subHeader,
  perks,
  actionLabel,
  onAction,
  subTitle,
  mainTitle,
  accordion,
  show,
  supportUrl,
  onClose,
  showAlert,
  onSignInClick,
  onAnalyticsEvent,
  className,
  language,
  ...props
}: WhoopProModalProps) {
  const closeModal = () => {
    onClose && onClose()
    onAnalyticsEvent &&
      onAnalyticsEvent('WHOOP Pro', {
        event: 'Skip WHOOP Pro',
        source: 'pro modal'
      })
  }
  const { t } = useTranslation('whoopProModal', language); //Language allows us an optional override of web-components language
  return (
    <Modal show={show} onClose={closeModal} disableAnimation>
      <div className={classes(show && styles.show, className)} {...props}>
        <div className={styles.contentContainer}>
          <ProLogo className={styles.proLogo} />
          <h1 className={styles.title}>{header}</h1>
          {subHeader && <span className={styles.subHeader}>{subHeader}</span>}
          {perks && (
            <ul>
              {perks?.map((perk: any) => (
                <li key={perk.description}>
                  <Icon name={perk.icon.name} className={styles.icon} />
                  {perk.description}
                </li>
              ))}
            </ul>
          )}
          <h3 className={styles.ctaBody}>{mainTitle}</h3>
          {actionLabel && onAction && (
            <Button
              variant={ButtonVariants.PRIMARY}
              size={ButtonSizes.LARGE}
              label={actionLabel}
              className={styles.joinWhoopProBtn}
              onClick={() => {
                onAction()
              }}
            />
          )}
          {onSignInClick && (
            <a className={styles.signinLink} onClick={(e) => onSignInClick(e)}>
              {t('signIn')}
            </a>
          )}
          <p className={styles.footer}>{subTitle}</p>
        </div>
        <div className={styles.faq}>
          {showAlert && (
            <Alert
              className={styles.alert}
              type={Alerts.INFO}
              children={t('redirectToJoin')}
              theme={StatusTagThemes.DEFAULT}
            />
          )}
          <Accordion className={styles.accordion} sections={accordion} />
          {supportUrl && (
            <div className={styles.faqLink}>
              <a
                href={supportUrl}
                onClick={() =>
                  onAnalyticsEvent &&
                  onAnalyticsEvent('WHOOP Pro', {
                    event: 'Clicked WHOOP Pro learn more link',
                  })
                }
                rel='noopener noreferrer'
                target='_blank'
              >
                {t('learnAbout')}
              </a>
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}

WhoopProModal.propTypes = {
  header: PropTypes.string,
  subHeader: PropTypes.string,
  perks: PropTypes.array,
  actionLabel: PropTypes.string,
  onAction: PropTypes.func,
  subTitle: PropTypes.string,
  mainTitle: PropTypes.string,
  accordion: PropTypes.array,
  show: PropTypes.bool,
  supportUrl: PropTypes.string,
  onSignInClick: PropTypes.func,
  showAlert: PropTypes.bool,
  onClose: PropTypes.func,
  onAnalyticsEvent: PropTypes.func,
  language: PropTypes.string
}
