import React from 'react'
import styles from './BigImageModal.module.scss'
import Modal, { ModalProps } from '../Modal/modal'
import { Button } from '../Button/index'
import { ButtonSizes, ButtonThemes, ButtonVariants } from '../../types/Buttons'
import { Col, Container, Row } from '../layout'
import { classes } from '../../utils'
import { Accordion, AccordionSection } from '../Accordion'

interface ActionProps {
  title: string
  onClick: () => void
}

export interface BigImageModalProps extends Omit<ModalProps, 'children'> {
  image: string
  title: string
  subtitle: string
  body: React.ReactNode
  primaryAction: ActionProps
  secondaryAction: ActionProps
  accordionSections?: AccordionSection[]
}

export const BigImageModal = ({
  image,
  title,
  subtitle,
  body,
  primaryAction,
  secondaryAction,
  className,
  show,
  onClose,
  disableAnimation,
  disableClose = false,
  slideAnimation,
  theme,
  accordionSections,
  ...props
}: BigImageModalProps) => {
  return (
    <Modal
      className={classes(styles.modal, className)}
      show={show}
      onClose={onClose}
      disableAnimation={disableAnimation}
      disableClose={disableClose}
      slideAnimation={slideAnimation}
      theme={theme}
      children={
        <div className={styles.wrapper}>
          <div className={styles.content}>
            <div className={styles.imageContainer}>
              <img className={styles.image} src={image} />
            </div>
            <div className={styles.contentContainer}>
              <div className={styles.textContainer}>
                <h1 className={styles.title}>{title}</h1>
                <h2 className={styles.subtitle}>{subtitle}</h2>
                <div className={styles.body}>{body}</div>
              </div>
              <Container className={styles.buttonsContainer}>
                <Row>
                  <Col xs={12}>
                    <Button
                      className={styles.actionButton}
                      theme={ButtonThemes.JOIN_FLOW}
                      variant={ButtonVariants.PRIMARY}
                      size={ButtonSizes.MEDIUM}
                      onClick={() => primaryAction.onClick()}
                    >
                      {primaryAction.title}
                    </Button>
                  </Col>
                  <Col xs={12}>
                    <Button
                      className={styles.actionButton}
                      variant={ButtonVariants.NORMAL}
                      size={ButtonSizes.MEDIUM}
                      onClick={() => secondaryAction.onClick()}
                    >
                      {secondaryAction.title}
                    </Button>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
          {accordionSections && (
            <Accordion
              className={styles.accordion}
              sections={accordionSections}
            ></Accordion>
          )}
        </div>
      }
      {...props}
    />
  )
}

export default BigImageModal
