import { useTranslation } from "react-i18next";
import ShippingIcon from '../assets/shipping.svg';
import styles from '../css/shipping-info.module.css';

const DeliveryNotice = () => {
  const { t } = useTranslation('shippingInfo');

  return (
    <div className={styles.deliveryNotice}>
      <img className={styles.deliveryNoticeImage} src={ShippingIcon} alt=''/>
      <div>
        <p className={styles.deliveryNoticeTitle}>{t('deliveryNoticeHeader')}</p>
        <p className={styles.deliveryNoticeDescription}>{t('deliveryNoticeMessage')}</p>
      </div>
    </div>
  );
}

export default DeliveryNotice;
