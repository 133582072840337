import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { useContext, useEffect } from "react";
import { WebComponentsContext } from "./WebComponentsContext";
import { useTranslation as usei18nextTranslation } from "react-i18next";
import { ALL_LANGUAGES, DEFAULT_LANGUAGE, Language } from '@whoop/i18n';

let isInitializing = false;
const TRANSLATION_FILENAME_REGEX = /.?\/?([a-z]+)\/.*.json$/
export function importTranslations(ns: string, context: __WebpackModuleApi.RequireContext) {
  initI18next();
  context.keys().forEach(fileName => {
    const match = fileName.match(TRANSLATION_FILENAME_REGEX);
    if (match && match[1]) {
      const lang = match[1];
      const translations = context(fileName);
      i18next.addResourceBundle(lang, ns, translations);
    }
  })
}

export function initI18next() {
  !i18next.isInitialized
  && !isInitializing
  && i18next
    .use(initReactI18next)
    .init({
      react: {
        useSuspense: false
      },
      fallbackLng: DEFAULT_LANGUAGE,
      debug: false,
      supportedLngs: ALL_LANGUAGES,
      saveMissing: true,
      missingKeyHandler: (lngs, ns, key) =>
        console.error(`[web-components] Missing key: ${key} in NS: ${ns} in Lang: ${lngs}`)
    });
  isInitializing = true;
}

export function useTranslation(ns: string, language?: Language) {
  const { t, i18n } = usei18nextTranslation(ns);
  const value = useContext(WebComponentsContext);
  const langPref = language ?? value.language;
  useEffect(() => {
    if (i18n.language !== langPref) {
      i18n.changeLanguage(langPref, (err: Error) => {
        if (err) console.error('Attempt to switch languages broke', err);
      });
    }
  }, [langPref, i18n]);

  return { t };
}
