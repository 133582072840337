/**
 * Adds a ripple given some parameters. Will also remove any existing ripples.
 * TODO: Currently this is dependant on css, should abstract into separate thing
 * @param element element to add ripple to
 * @param event event for mouse coords
 * @param className ripple class name
 */
export const addRipple = function(element, event, className) {
  const ripples = element.getElementsByClassName(className);
  if (ripples) {
    Array.from(ripples).forEach(el => {
      const createdAt = el.getAttribute('data-created-at');
      if (!createdAt || (parseInt(createdAt) + 1000) < Date.now()) { // ripple expires after 1 second
        el.remove && el.remove();
      }
    });
  }
  const diameter = Math.max(element.clientWidth, element.clientHeight);
  const radius = diameter / 2;

  const ripple = document.createElement('span');
  const elementRect = element.getBoundingClientRect();
  ripple.style.width = ripple.style.height = `${diameter}px`;
  ripple.style.left = `${event.clientX - (elementRect.left + radius)}px`;
  ripple.style.top = `${event.clientY - (elementRect.top + radius)}px`;
  ripple.classList.add(className);
  ripple.setAttribute('data-created-at', Date.now());
  element.append(ripple);
};
