export enum TooltipThemes {
  DEFAULT = 'default',
  UNITE = 'unite',
}

export enum TooltipDirection {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
}
