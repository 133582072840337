import React from 'react';
import styles from '../css/header.module.css';

const Header = () => {
  return (
    <div id="header">
      <div className={ styles.container }>
          <h1>WHOOP</h1>
      </div>
    </div>
  );
}

export default Header;