import React from 'react';
import PropTypes from 'prop-types';
import styles from './pulsing-logo.module.scss';
import { c } from '../../utils';

export const PulsingLogo = ({ color = 'light', size = 'large' }) => {
  return (
    <div
      className={c(
        styles.pulsingLogo,
        styles[`pulsingLogo-${size}`],
        color === 'dark' && styles.overlayLogo
      )}
    />
  );
};

PulsingLogo.propTypes = {
  /*
   * select color style of pulsing logo
   */
  color: PropTypes.oneOf(['light', 'dark']),
  size: PropTypes.oneOf(['small', 'medium', 'large'])
};
