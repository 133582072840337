/* global HTMLDivElement */
import React from 'react';
import { c } from '../../utils';
import styles from './card.module.scss';

export interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
};

export const Card = ({ children, className, ...props }: CardProps) => (
  <div className={c(styles.card, className)} {...props}>
    {children}
  </div>
);
