import React from 'react';
import styles from '../css/account-header.module.css';
import { gtagCommand } from '../services/monitoring';
import {useTranslation} from "react-i18next";

const AccountHeader = (props) => {
  const { t } = useTranslation('header');
  const { isGift, email } = props;

  const updateEmail = (e) => {
    e.preventDefault();
    gtagCommand('Order-Status', 'click', 'Update Email');
    window.open('//app.whoop.com/settings/profile');
  };

  if (isGift) {
    return <></>;
  }

  return (
    <div className={ styles.accountHeader }>
      <div className={ styles.headerAccount }>
        <div className={ styles.headerText }>
          {t('yourAccountIsUnder', { email })}&nbsp;
          {t('wrongEmail')}&nbsp;
          <a href="/" onClick={updateEmail} target="_blank" rel="noopener noreferrer">
            {t('updateYourAccount')}
          </a>
        </div>
      </div>
    </div>
  );
}

export default AccountHeader;
