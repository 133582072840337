import React from 'react';
import PropTypes from 'prop-types';
import { AutoCompleteInput, AutoCompleteResult, AutoCompleteResultUpdate, Search } from '../AutoCompleteInput/index';
import { searchAddresses, searchAddressesWithSelection } from './address-lookup-service';

export type AddressAutoCompleteProps = {
    onSelect: Function,
    className?: string,
    smartyApiKey: string
};

export type AddressSuggestion = {
    street_line: string,
    secondary?: string,
    entries: number,
    city: string,
    state: string,
    zipcode: string
};

export type AddressSuggestions = {
    suggestions: AddressSuggestion[]
};

/*
  Note this component is dependent on Smarty: https://www.smarty.com/products/us-address-autocomplete
  As of 9/21/2022 we are in a trial period with Smarty
  please reach out to Patrick Cavanaugh if you have a need for this component
*/
export const AddressAutoComplete = ({ onSelect, smartyApiKey, ...props }: AddressAutoCompleteProps) => {

    const _onSelect = async ({ result }: { result: AddressSuggestion}): Promise<AutoCompleteResultUpdate> => {
        if (result.entries > 1) {
            const selectedAddress = `${result.street_line} ${result.secondary} (${result.entries}) ${result.city} ${result.state} ${result.zipcode}`;
            const searchText = `${result.street_line} ${result.secondary}`;
            const detailedResult = await searchAddressesWithSelection(smartyApiKey, searchText, selectedAddress);
            return {
                text: `${result.street_line} ${result.secondary}`,
                results: formatResults(detailedResult)
            };
        }
        onSelect(result);
        return {
            text: `${result.street_line}`,
            results: []
        };
    };

    const formatSuggestion = (suggestion: AddressSuggestion) => {
        let formattedValue = '';
        formattedValue += suggestion.street_line;
        if (suggestion.secondary) {
            formattedValue += ` ${suggestion.secondary}`;
        }
        if (suggestion.entries > 1) {
            formattedValue += ` (${suggestion.entries} more entries)`
        }
        return formattedValue + ` ${suggestion.city}, ${suggestion.state}, ${suggestion.zipcode}`
    };

    const formatResults = (result: AddressSuggestions): AutoCompleteResult[] => {
        const suggestions = result.suggestions;
        if (!suggestions) return [];
        return suggestions.map((a) => {
            const formattedSuggestion = formatSuggestion(a);
            return {
                element: <>{ formattedSuggestion }</>,
                result: a,
                key: formattedSuggestion
            };
        });
    };
    
    const search: Search = async (searchText) => {
        if (!searchText ) {
            return [];
        }
        const result = await searchAddresses(smartyApiKey, searchText);
        return formatResults(result);
    };

    return (
        <>
            <AutoCompleteInput
                search={ search }
                name="addressAutoComplete"
                placeholder="Address"
                onSelect={ _onSelect }
                { ...props }
            />
        </>
    );
};

AddressAutoComplete.propTypes = {
    onSelect: PropTypes.func.isRequired,
    smartyApiKey: PropTypes.string.isRequired,
    className: PropTypes.string,
    onChange: PropTypes.func
};
