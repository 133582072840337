import React, { useEffect, useRef } from "react";
import styles from './slideshow.module.scss';
import 'flickity/css/flickity.css';
import { c } from "../../utils";
import Flickity from 'react-flickity-component';
import { BasicComponentProps } from "../../types";
import { Badge } from "../Badge";

export type SlideshowProps = {
  value?: number; // current index of image to show
  pageDots?: boolean;
  prevNextButtons?: boolean;
  wrapAround?: boolean;
  onChange?: (index: number) => void;
  parentSizing?: boolean;
  multiImage?: boolean;
  overlay?: React.ReactNode;
  cellAlignForWrapAround?: boolean;
} & BasicComponentProps;

type FlickityElement = Flickity & {
  element: HTMLElement;
  isDraggable?: boolean;
}

let LOCKED_SLIDESHOWS: FlickityElement[] = [];

export const Slideshow = ({
  value,
  pageDots,
  prevNextButtons,
  wrapAround,
  children,
  className,
  onChange,
  parentSizing,
  multiImage,
  overlay,
  cellAlignForWrapAround,
  ...props
}: SlideshowProps): JSX.Element => {
  const $flickity = useRef<FlickityElement>(null);

  useEffect(() => {
    $flickity.current.on('change', (index: number) => {
      onChange && onChange(index);
    });

    $flickity.current.on('dragStart', (event: MouseEvent) => {
      const path = event.composedPath();
      let slideshowCount = 0;
      for (let i in path) {
        const el = path[i] as HTMLElement;
        if (el === $flickity.current.element) {
          break;
        }
        if (el?.className?.includes && el?.className?.includes('flickity-enabled')) {
          slideshowCount += 1;
        }
      }
      if (slideshowCount > 0) {
        // Lock this slideshow
        $flickity.current.isDraggable = false;
        LOCKED_SLIDESHOWS.push($flickity.current);
      }
    });

    $flickity.current.on('dragEnd', () => {
      // unlock all slideshows
      LOCKED_SLIDESHOWS = LOCKED_SLIDESHOWS.filter(flickity => {
        flickity.isDraggable = true;
      });
    })
  }, [$flickity]);

  useEffect(() => {
    if ($flickity.current) {
      $flickity.current.select(value);
    }
  }, [value]);


  return <div
    className={c(
      styles.slideshow,
      pageDots && styles.withDots,
      parentSizing && styles.parentSizing,
      className)}
    {...props}>
    <Flickity
      flickityRef={(c) =>
        // @ts-ignore: Flickity library does not support a generic ref type for this
        ($flickity.current = c)
      }
      className={styles.flickity}
      options={{
        adaptiveHeight: false,
        lazyLoad: true,
        dragThreshold: 8,
        initialIndex: 0,
        imagesLoaded: true,
        accessibility: true,
        // this calculates the initial tranform wrong if the wrapAround and multiImage are true. Added the fix from this github issue
        // https://github.com/metafizzy/flickity/issues/1016
        cellAlign: multiImage ? cellAlignForWrapAround? '0.01': 'left' : 'center',
        pageDots,
        prevNextButtons,
        wrapAround,
        groupCells: 1,
        contain: multiImage
      }}
    >
      {children}
    </Flickity>
    {overlay}
  </div>

};
