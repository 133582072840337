import { Button } from '../../components/Button'
import { classes } from '../../utils'
import React, { KeyboardEvent, useEffect, useRef, useState } from 'react'
import { ButtonVariants } from '../../types/Buttons'
import { BasicComponentProps } from '../../types'
import styles from './dropdown.module.scss'
import { DropdownThemes } from '../../types/Dropdown'
import TinyTriangleDownIcon from '../../icons/Tiny/ic_tiny_triangle_down.svg'
import CheckIcon from '../../icons/Actions/ic_check.svg'

export type DropdownProps = {
  options: string[]
  onOptionSelected: (option: string) => void
  selectedOption: string
  label?: string
  theme?: DropdownThemes
  round?: boolean
} & BasicComponentProps

export const Dropdown = ({
  options,
  onOptionSelected,
  selectedOption,
  label,
  className,
  theme = DropdownThemes.DEFAULT,
  round = true,
  ...props
}: DropdownProps) => {
  const [showMenuOptions, setShowMenuOptions] = useState<boolean>(false)
  const dropdownRef = useRef<HTMLDivElement>()

  const handleClickOutside = (event: MouseEvent) => {
    // check if click is outside of div
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setShowMenuOptions(false)
    }
  }

  useEffect(() => {
    // add event listener to document object
    document.addEventListener('click', handleClickOutside)

    // cleanup function to remove event listener
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  const handleOptionSelect = (option: string) => {
    setShowMenuOptions(false)
    onOptionSelected(option)
  }

  const checkIfEnterButton = (
    option: string,
    event: KeyboardEvent<HTMLDivElement>
  ) => {
    if (event.code === 'Enter') {
      handleOptionSelect(option)
    }
  }

  return (
    <div
      className={classes(
        className,
        styles.dropdown,
        'whoop-typography',
        theme !== DropdownThemes.DEFAULT && `theme-${theme}`
      )}
      ref={dropdownRef}
      {...props}
    >
      <Button
        onClick={() => setShowMenuOptions(!showMenuOptions)}
        className={classes(
          styles.dropdownButton,
          round ? styles.round : styles.squared
        )}
        variant={ButtonVariants.NORMAL}
        role='menu'
      >
        <p className={classes(styles.text, 'p5')}>{selectedOption ?? label}</p>
        <TinyTriangleDownIcon className={styles.icon} />
      </Button>
      {showMenuOptions && (
        <div className={styles.dropdownMenu}>
          {options.map((option) => (
            <div
              key={option}
              className={styles.menuItem}
              onClick={() => handleOptionSelect(option)}
              onKeyDown={(event) => checkIfEnterButton(option, event)}
              role='menuitem'
              tabIndex={0}
            >
              <p className='p5'>{option}</p>
              {option === selectedOption && (
                <CheckIcon name='check' className={styles.checkIcon} />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
