import { getAuthToken, setAuthToken } from './auth-client';
import settings from '../config';
import queryString from 'query-string';

const url = settings.apiUrl;

const setToken = (body) => {
  if (body.token) {
    setAuthToken({
      accessToken: body.token,
      isRegionWithNoZipCodeCustomer: body.isRegionWithNoZipCode
    });
  }
};

const makeRequest = async (endpoint, { body, ...customConfig } = {}) => {
  const headers = {
    'content-type': 'application/json',
    ...customConfig.headers
  };

  const token = getAuthToken();

  if (token && token.accessToken) {
    headers.authorization = `bearer ${token.accessToken}`;
  }

  const config = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers
  }

  if (body) {
    config.body = JSON.stringify(body);
  }

  const result = await window.fetch(`${url}${endpoint}`, config);

  if (result.status === 204) return;

  if (result.status > 300) {
    return Promise.reject(new Error(result.statusText));
  }
  return result.json();
};

const getOrderLookUp = async (data) => {
  const body = await apiService.makeRequest('/shipping-status/orders/?' + queryString.stringify(data));
  setToken(body);

  return body.order;
};

const getStripeOrderLookUp = async (data) => {
  const body = await apiService.makeRequest('/shipping-status/stripe/order/?' + queryString.stringify(data));
  setToken(body);
};

const login = async (data) => {
  const body = await apiService.makeRequest('/shipping-status/login/?' + queryString.stringify(data));
  setToken(body);

  return body.status;
};

const updateAddress = async (data) => {
  const body = await apiService.makeRequest('/shipping-status/address/', {
    body: data
  });
  setToken(body);

  return body.status;
};

const chargeReservationOrder = async (data) => {
  const body = await apiService.makeRequest('/shipping-status/reservation/charge/', {
    body: data
  });
  setToken(body);

  return {
    status: body.status,
    description: body.description
  };
}

const cancelOrder = async (data) => {
  const body = await apiService.makeRequest('/shipping-status/cancel/?' + queryString.stringify(data));
  setToken(body);

  return body.status;
};

const apiService = {
  getOrderLookUp,
  getStripeOrderLookUp,
  login,
  updateAddress,
  chargeReservationOrder,
  cancelOrder,
  makeRequest
};

export default apiService;
