import bicep from '../../../assets/sizing/bicep-sizing.png';
import arm from '../../../assets/sizing/arm-sizing.png';
import womensTop from '../../../assets/sizing/womens-top-sizing.png';
import womensBottom from '../../../assets/sizing/womens-bottom-sizing.png';
import mensTop from '../../../assets/sizing/mens-top-sizing.png';
import mensBottom from '../../../assets/sizing/mens-bottom-sizing.png';
import assosMensShorts from '../../../assets/sizing/assos-mens-shorts-sizing.png';
import assosWomensShorts from '../../../assets/sizing/assos-womens-shorts-sizing.png';
import {
  ARM_CHART,
  BICEP,
  HYDROSLEEVE,
  JOGGERS,
  MENS_BOXERS_CHART,
  MENS_SHORTS_CHART,
  MENS_TOP_CHART,
  UNISEX_TEE,
  UNISEX_TOP,
  WOMENS_BRA_CHART,
  WOMENS_LEGGINGS_CHART,
  TYR_MENS,
  TYR_WOMENS,
  ASSOS_MENS_SHORTS,
  ASSOS_WOMENS_SHORTS,
} from "./dataStore";

export const getSizingChart = (type) => {
  switch (type) {
    case 'BICEP':
      return BICEP;
    case 'ARM':
      return ARM_CHART;
    case 'MENS_TOP':
      return MENS_TOP_CHART;
    case 'MENS_SHORTS':
      return MENS_SHORTS_CHART;
    case 'MENS_BOXERS':
      return MENS_BOXERS_CHART;
    case 'WOMENS_BRA':
      return WOMENS_BRA_CHART;
    case 'WOMENS_LEGGINGS':
      return WOMENS_LEGGINGS_CHART;
    case 'JOGGERS':
      return JOGGERS;
    case 'UNISEX_TOP':
      return UNISEX_TOP;
    case 'UNISEX_TEE':
      return UNISEX_TEE;
    case 'HYDROSLEEVE':
      return HYDROSLEEVE;
    case 'TYR_MENS':
      return TYR_MENS;
    case 'TYR_WOMENS':
      return TYR_WOMENS;
    case 'ASSOS_MENS_SHORTS':
      return ASSOS_MENS_SHORTS;
    case 'ASSOS_WOMENS_SHORTS':
      return ASSOS_WOMENS_SHORTS;
  }
};

export const getSizingData = (type) => {
  const chart = getSizingChart(type);
  const howToMeasure = {};
  Object.keys(chart).forEach(key => {
    if (type.includes('ASSOS')) {
      if (!type.includes('WOMEN') && key === 'hip') {
        howToMeasure[key] = `${key}_assos_m`
      } else {
        howToMeasure[key] = `${key}_assos`
      }
    } else if (type.includes('WOMEN') && key === 'waist') { // exception case
      howToMeasure[key] = 'waist_f';
    }
    else {
      howToMeasure[key] = key;
    }
  });
  delete howToMeasure['cup'];
  delete howToMeasure['height'];
  return {
    chart: chart,
    howToMeasure: howToMeasure
  };
};

export const getSizingImage = (type) => {
  switch (type) {
    case 'BICEP':
      return bicep;
    case 'ARM':
      return arm;
    case 'MENS_TOP':
      return mensTop;
    case 'MENS_SHORTS':
    case 'MENS_BOXERS':
      return mensBottom;
    case 'WOMENS_BRA':
      return womensTop;
    case 'WOMENS_LEGGINGS':
      return womensBottom;
    case 'ASSOS_MENS_SHORTS':
      return assosMensShorts;
    case 'ASSOS_WOMENS_SHORTS':
      return assosWomensShorts;
  }
};
