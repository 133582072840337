import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../css/footer.module.css';

const Footer = () => {
  const { t } = useTranslation('footer');
  const currentDate = new Date();
  const year = currentDate.getFullYear();

  return (
    <div id="footer" className={styles.row}>
      <div className={styles.footer}>
        <div className={styles.footerWrapper}>
          <div className={styles.footerLogo} />
          <ul>
            <li>
              <a href="http://whoop.com/" target="_blank" rel="noopener noreferrer">
                {t('copyright', { copyrightSymbol: '\u00A9', year })}
              </a>
            </li>
            <li>
              <a href="http://whoop.com/termsofuse/" target="_blank" rel="noopener noreferrer">
                {t('termsOfService')}
              </a>
            </li>
            <li>
              <a href="http://whoop.com/privacy/" target="_blank" rel="noopener noreferrer">
                {t('privacyPolicy')}
              </a>
            </li>
            <li>
              <a href="https://support.whoop.com/s/article/Return-Policy?language=en_US" target="_blank" rel="noopener noreferrer">
                {t('refundPolicy')}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
