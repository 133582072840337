import { useExperiment as _useExperiment, emitter, experimentDebugger } from '@marvelapp/react-ab-test';

const experimentKeyPrefix = 'experiment_orderstatus_';

const getExperimentKey = (key) => {
  return experimentKeyPrefix + key;
}

export const useExperiment = (experimentKey, variantList) => {
  const prefixedKey = getExperimentKey(experimentKey);
  emitter.defineVariants(prefixedKey, variantList);
  return _useExperiment(prefixedKey);
}

export const selectVariant = (experimentKey, variantMap) => {
  const prefixedKey = getExperimentKey(experimentKey);
  const { selectVariant } = _useExperiment(prefixedKey);
  return selectVariant(variantMap);
};

export const enableDebugger = () => {
  experimentDebugger.enable();
  experimentDebugger.setDebuggerAvailable(true);
};

export const experiments = {
  cancelOrder: getExperimentKey('cancel_order')
};
