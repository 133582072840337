import React, { useState, useEffect } from 'react';
import apiService from '../services/api-service';
import LookUpOrder from './look-up-order';
import { dataDogLog } from '../services/monitoring';

const LookUpStripe = (props) => {
  const { stripeOrder, orderZip, orderEmail, gift } = props.data;
  const [ hasCredentials, setHasCredentials ] = useState(false);

  useEffect(() => {
    const credentials = {};
    if (gift) {
      credentials.gift = gift;
    } else {
      credentials.stripeOrder = stripeOrder;
      credentials.orderZip = orderZip;
      
      if (orderEmail) {
        credentials.isRegionWithNoZipCode = true;
        credentials.orderEmail = orderEmail;
      }
    }

    apiService.getStripeOrderLookUp(credentials)
      .then(() => {
        setHasCredentials(true);
      }, (error) => {
        console.log(error.message);
        dataDogLog('stripeLookUp', credentials);
        setHasCredentials(false);
      });
  }, [stripeOrder, orderZip, orderEmail, gift]);

  return (
    <div>
      { hasCredentials ?
        <LookUpOrder hasCredentials={hasCredentials} />
        : null }
    </div>
  );
};

export default LookUpStripe;
