import { Button } from '../../components/Button/index';
import styles from './uniteFooter.module.scss';
import {ButtonThemes, ButtonVariants} from "../../types/Buttons";
import React, {useEffect, useState} from 'react';
import {classes} from "../../utils";
import FooterImage from '../../assets/unite-logo.svg';
import {Breakpoint, useLessThanBreakpoint} from "../layout/useLessThanBreakpoint";

export type UniteFooterProps = {
  logo: boolean;
  landingPage?: boolean;
  showHelpCenter?: boolean;
  termsUrl?: string;
  privacyUrl?: string;
};

export function UniteFooter({logo, landingPage = false, showHelpCenter = true, termsUrl, privacyUrl}: UniteFooterProps) {
  const helpCenterClick = () => { window.open('https://api.prod.whoop.com/url-mapping-service/v1/UNITE_HOME', "_blank", "noreferrer") };
  const aboutWhoopUniteClick = () => { window.open('https://whoop.com/group-sales', "_blank", "noreferrer") };
  const termsOfUseClick = () => { window.open(termsUrl ?? 'https://whoopunite.com/legal/terms-of-use', "_blank", "noreferrer") };
  const privacyPolicyClick = () => { window.open(privacyUrl ?? 'https://whoopunite.com/legal/privacy', "_blank", "noreferrer") };

  const isLessThanMd = useLessThanBreakpoint(Breakpoint.MD);

  return (
    <div className={classes(styles.uniteFooterWrapper, landingPage && styles.relativeContainer)}>
      {!isLessThanMd && (
        <div>
          {logo ? (
            <FooterImage className={styles.logo} height={80} width={200} />
          ) : (
            <div className={styles.actionContainer}>
              {showHelpCenter && (
                <Button
                label="help center"
                variant={ButtonVariants.LINK}
                theme={ButtonThemes.DARK}
                onClick={helpCenterClick}
              />
              )}
              <Button
                label="about whoop unite"
                variant={ButtonVariants.LINK}
                theme={ButtonThemes.DARK}
                onClick={aboutWhoopUniteClick}
              />
            </div>
          )}
        </div>
      )}
      <div className={styles.footerLinks}>
        <div className={styles.copyright}>Copyright © 2023 Whoop</div>
        <Button variant={ButtonVariants.LINK} theme={ButtonThemes.DARK} onClick={termsOfUseClick} className={styles.details}>Terms of Use</Button>
        <Button variant={ButtonVariants.LINK} theme={ButtonThemes.DARK} onClick={privacyPolicyClick} className={styles.details}>Privacy Policy</Button>
      </div>
    </div>
  );
}
