import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { initMonitoring } from './services/monitoring';
import { PulsingLogo } from '@whoop/web-components';

import './i18n'; // needs to load

initMonitoring();

ReactDOM.render(
  <BrowserRouter>
      <Suspense fallback={<PulsingLogo />}>
          <App />
      </Suspense>
  </BrowserRouter>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
