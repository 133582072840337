import React from 'react';
import {importTranslations, useTranslation} from '../../../utils/i18n';
import Modal from "../../Modal/modal";
import styles from "./upgradeModal.module.scss";
import {c} from "../../../utils";
import {Button} from "../../Button/index";
import {ButtonSizes, ButtonThemes, ButtonVariants} from "../../../types/Buttons";
import {ModalThemes} from "../../../types/Modal";
import {Trans} from "react-i18next";

importTranslations('upgrade-modal', require.context('./languages/', true))

export interface UpgradeModalProps extends React.HTMLAttributes<HTMLDivElement> {
  show: boolean
  email: string
  onUpgrade: () => void
  onClose: () => void
}

export function UpgradeModal({
  show,
  email,
  onUpgrade,
  onClose,
  className,
  ...props
}: UpgradeModalProps) {
  const { t } = useTranslation('upgrade-modal')

  return (
    <Modal
      show={show}
      onClose={onClose}
      className={c(className, styles.modal)}
      theme={ModalThemes.JOIN_FLOW}
      children={
        <div className={styles.contentWrapper}>
          <h1 className={styles.title}>{t('title')}</h1>
          <p className={styles.description}>
            <Trans>
              {t('description', {email})}
            </Trans>
          </p>
          <Button
            className={styles.upgradeButton}
            theme={ButtonThemes.JOIN_FLOW}
            variant={ButtonVariants.PRIMARY}
            size={ButtonSizes.LARGE}
            onClick={onUpgrade}
          >
            {t('upgradeButton')}
          </Button>
        </div>
      }
      {...props}
    />
  )
}
