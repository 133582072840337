import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../../index.module.scss';
import styles from './textarea.module.scss';

/**
 * Textarea component
 */
export const TextArea = ({ value = '', name, onChange, errorValue, characterLimit, rows = '10', cols = '35', ...props }) => {
  const [focused, setFocused] = useState(false);

  const handleChange = (event) => {
    let text = event.target.value;
    if (characterLimit && text.length > characterLimit) {
      text = text.substr(0, characterLimit);
    }
    onChange(text);
  };

  // --- Render ---
  return (
    <div
      className={styles.textarea + ' ' +
      (focused ? styles.focused : '') + ' ' +
      (focused || value ? styles.active : '')}
    >
      <textarea
        value={value}
        name={name}
        aria-label={props['aria-label'] || 'text'}
        onChange={handleChange}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        {...props}
      />
      <div className={styles['sub-text']}>
        {errorValue && <p className={styles.error}>{errorValue}</p>}
      </div>
    </div>
  );
};

TextArea.propTypes = {
  /**
   * Name of input
   */
  name: PropTypes.string,
  /**
   * Optional onChange handler that is called whenever Input is updated
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Value of the text input (only used as initial value, or if used fully if useExternalState is true
   */
  value: PropTypes.string,
  /**
   * Optional value displayed in red under the text area
   */
  errorValue: PropTypes.string,
  /**
   * Optional enforced character limit displayed under the text area. Handles Copy/Paste above limit by cutting off at limit
   */
  characterLimit: PropTypes.number,
};
