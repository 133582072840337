import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useForm from 'react-hook-form';
import InputField from './input-field';
import CountrySelection from './country-selection';
import StateSelection from './state-selection';
import apiService from '../services/api-service';
import styles from '../css/modals.module.css';
import { dataDogLog, gtagCommand } from '../services/monitoring';
import { hasRegionWithNoZipCodeCustomer } from '../services/support';

/* eslint react/prop-types: 0 */
const UpdateUserAddress = (props) => {
  const { t } = useTranslation('updateUserAddress');
  const { handleSubmit, register, watch } = useForm();
  const [hasError, setHasError] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { data, updateAccountAddress, closeModal } = props;
  const {
    stripeOrderId,
    whoopOrderId,
    currencyType,
    firstName,
    lastName,
    opportunityId,
    country,
    shopifyOrder,
  } = data;

  const orderError = (err) => {
    console.log(err);
    setHasError(true);
    setIsLoading(false);
    dataDogLog('updateShippingAddress', data);
  };

  const editAddress = (values) => {
    setHasError(null);
    setIsUpdating(true);
    setIsLoading(true);
    const param = {
      orderId: data.orderId,
      stripeOrderId,
      whoopOrderId,
      shopifyOrder,
      opportunityId,
      name: `${firstName} ${lastName}`,
      ...values,
    };

    apiService.updateAddress(param).then(() => {
      updateAccountAddress(values);
      gtagCommand('Order-Status', 'confirm', 'Updated Shipping Address - Complete');
    }, (error) => {
      orderError(error.message);
    });
  };

  const handleEnterKey = (e) => {
    const {
      address1, address2, city, state, shipZip,
    } = watch();

    const values = {
      address1,
      address2,
      city,
      state,
      shipZip,
      country,
    };

    if (e.key === 'Enter' && !isDisabled) {
      editAddress(values);
    }
  };

  useEffect(() => {
    const {
      address1, city, state, shipZip,
    } = watch();

    if (address1 && city && state && shipZip && country && !isUpdating) {
      setIsDisabled(false);
    } else if (hasRegionWithNoZipCodeCustomer() && address1 && city && country && !isUpdating) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [watch, isUpdating]);

  return (
    <div className={styles.overlay}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}>{t('updateShippingAddress')}</div>
        </div>
        <div className={styles.content}>
          { hasError ? (
            <div className={styles.serverAlert}>
              {t('weEncounteredAnError')}
            </div>
          ) : null }
          <div className={styles.inputField}>
            <InputField
              type="text"
              name="address1"
              placeholder={hasRegionWithNoZipCodeCustomer() ? t('addressPoBox') : t('address')}
              inputRef={register}
              testId="address-1"
              handleEnterKey={handleEnterKey}
            />
          </div>
          { !hasRegionWithNoZipCodeCustomer()
            ? (
              <div className={styles.inputField}>
                <InputField
                  type="text"
                  name="address2"
                  placeholder={t('addressLine2')}
                  inputRef={register}
                  testId="address-2"
                  handleEnterKey={handleEnterKey}
                />
              </div>
            )
            : null}
          <div className={styles.inputField}>
            <InputField
              type="text"
              name="city"
              placeholder={hasRegionWithNoZipCodeCustomer() ? t('emirateCity') : t('city')}
              inputRef={register}
              testId="city"
              handleEnterKey={handleEnterKey}
            />
          </div>
          { !hasRegionWithNoZipCodeCustomer()
            ? (
              <div className={styles.inputField}>
                { currencyType === 'EUR' || currencyType === 'GBP'
                  ? (
                    <InputField
                      type="text"
                      name="state"
                      placeholder={t('state')}
                      inputRef={register}
                      testId="state"
                      handleEnterKey={handleEnterKey}
                    />
                  ) : (
                    <StateSelection
                      country={country}
                      name="state"
                      inputRef={register}
                    />
                  )}
              </div>
            )
            : null}
          { !hasRegionWithNoZipCodeCustomer()
            ? (
              <div className={styles.inputField}>
                <InputField
                  type="text"
                  name="shipZip"
                  placeholder={t('zipCode')}
                  inputRef={register}
                  testId="ship-zip"
                  handleEnterKey={handleEnterKey}
                />
              </div>
            )
            : null}
          <div className={styles.inputField}>
            <CountrySelection
              currency={currencyType}
              country={country}
              name="country"
              inputRef={register}
            />
          </div>
        </div>
        <div className={styles.buttons}>
          <div className={styles.hideOnMobile}>
            <button
              className={styles.btn}
              onClick={closeModal}
              type="submit"
            >
              {t('cancel')}
            </button>
            <button
              className={styles.btnGreen}
              disabled={isDisabled}
              onClick={handleSubmit(editAddress)}
              type="submit"
              data-test-id="update-address"
            >
              {t('save')}
            </button>
          </div>
          <div className={styles.showOnMobile}>
            <button
              className={styles.btnGreen}
              disabled={isDisabled}
              onClick={handleSubmit(editAddress)}
              type="submit"
            >
              {t('save')}
            </button>
            <button className={styles.btn} onClick={closeModal} type="submit">
              {t('cancel')}
            </button>
          </div>
        </div>
        { isLoading ? <div className={styles.loading}>&nbsp;</div> : null }
      </div>
    </div>
  );
};

export default UpdateUserAddress;
