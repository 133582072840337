import React, { useEffect, useMemo, useState } from "react";
import { ProductDetailsGrid, ProductDetailsGridProps } from "../ProductDetailsGrid";
import styles from './pack-details.module.scss'
import { ProductItem, ProductNode } from '../../../types/Products'
import { AnalyticsFunction, Optional } from "../../../types";
import { flattenNodeItems } from "../../../utils/productUtils";
import {MiniProductSelector} from "../../MiniProductSelector";
import { ProductMedia } from "../ProductMedia";

export interface PackDetailsProps extends Omit<ProductDetailsGridProps, "onChange"> {
  node: ProductNode;
  packNodes: ProductNode[];
  value?: Optional<ProductItem>[];
  onChange?: (items: ProductItem[]) => any;
  onClickFullDetails?: (selection?: ProductItem, node?: ProductNode) => any;
  onAnalyticsEvent?: AnalyticsFunction;
}

export function PackDetails({node,
  packNodes,
  children,
  title,
  isProDiscount,
  withWhoopProPrice,
  originalPrice,
  price,
  className,
  value,
  onChange,
  onClickFullDetails,
  scrollParentRef,
  onAnalyticsEvent,
  ...props
  }: PackDetailsProps) {
  const description = node?.product_info?.description
  const initProductArray  = useMemo(() => packNodes?.map(n => flattenNodeItems(n)[0]), []);
  const [packItems, setPackItems] = useState<ProductItem[]>(initProductArray)

  const setSelection = (item: ProductItem, index: number) => {
    const newArr = [...packItems]
    newArr[index] = item
    const context: { [key: string]: string } = {};
    newArr.forEach((product, index) => {
      context['handle-' + (index + 1)] = packNodes[index].product_info.handle;
      context['sku-'+ (index + 1)] = product?.sku;
      if (product?.color) { context['color-' + (index + 1)] = product?.color?.handle; }
      if (product?.size) { context['size-' + (index + 1)] = product?.size?.handle; }
      if (product?.inseam) { context['inseam-' + (index + 1)] = product?.inseam?.handle; }
    });
    if (packItems?.[index]?.color) { context.color_old = packItems?.[index]?.color?.handle; }
    if (packItems?.[index]?.size) { context.size_old = packItems?.[index]?.size?.handle; }
    if (packItems?.[index]?.inseam) { context.inseam_old = packItems?.[index]?.inseam?.handle; }
    if (item?.color) { context.color_old = item?.color?.handle; }
    if (item?.size) { context.size_old = item?.size?.handle; }
    if (item?.inseam) { context.inseam_old = item?.inseam?.handle; }

    onAnalyticsEvent && onAnalyticsEvent('Pack Details Option Selected', {
      handle: packNodes?.[index]?.product_info?.handle,
      sku_old: packItems?.[index]?.sku,
      sku_new: item?.sku,
      item_index: index,
      ...context
    });
    setPackItems(newArr);
    onChange && onChange(newArr);
  }

  useEffect(() => {
    if (value) {
      setPackItems(value);
    }
  }, [value]);

  return (
    <ProductDetailsGrid
      media={node?.product_info?.media?.map((item) => (
        <ProductMedia {...item} key={item.id} />
      ))}
      title={title}
      price={price}
      originalPrice={originalPrice}
      withWhoopProPrice={withWhoopProPrice}
      isProDiscount={isProDiscount}
      isFancy={false}
      className={className}
      scrollParentRef={scrollParentRef}
      onImageChange={(index) => {
        onAnalyticsEvent && onAnalyticsEvent('Product Details Viewed Image', {
          product_handle: node?.product_info?.handle,
          image_index: index,
        });
      }}
      noImageScroll={true}
      description={description}
      onAnalyticsEvent={onAnalyticsEvent}
      {...props}
    >
      <div className={styles.topPadding}>
        {packNodes?.map((packItem, index) => (
          <MiniProductSelector
            key={index}
            node={packItem}
            value={value?.[index]}
            onChange={newValue => setSelection(newValue, index)}
            productIndex={index}
            onClickFullDetails={onClickFullDetails}
          />
        ))}
      </div>
      {children}
    </ProductDetailsGrid>
  );
}
