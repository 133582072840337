import React, {forwardRef} from 'react';
import styles from './modal.module.scss';
import {c} from '../../utils';
import {IconButton} from '../IconButton';
import {FocusBackground} from '../FocusBackground';
import {ModalThemes} from "../../types/Modal";

export interface ModalProps extends React.HTMLAttributes<HTMLDivElement>  {
  show: boolean;
  onClose: Function;
  disableAnimation?: boolean;
  disableClose?: boolean;
  slideAnimation?: boolean;
  theme?: ModalThemes;
};

export const Modal = forwardRef<any, ModalProps>(({
  children,
  className,
  show,
  onClose,
  disableAnimation,
  disableClose = false,
  slideAnimation,
  theme,
  ...props
}: ModalProps, ref) => {
  const callClose = () => onClose && !disableClose && onClose();

  return (
    <>
      <FocusBackground
        show={show}
        onClick={callClose}
        style={theme === ModalThemes.UNITE && show && {background: 'var(--color-black-alpha-800)'}}
      />
      <div
        id='modal'
        className={c(
          styles.modal,
          show && styles.show,
          disableAnimation && styles.disableAnimation,
          theme !== ModalThemes.DEFAULT && `theme-${theme}`,
          slideAnimation && 'type-slide',
          className
        )}
        ref={ref}
        {...props}
      >
        <div className={styles.topBar}>
          { !disableClose
            && <IconButton
              className={styles.closeButton}
              name='cross'
              onClick={callClose}
              variant='link'
            />
          }
        </div>

        {children}
      </div>
    </>
  );
});

export default Modal;

