import React from 'react';
import styles from '../css/product-info.module.css';
import { isVatInclusive, formatPrice, formatVatInclusivePrice } from '../services/support';
import { useTranslation } from 'react-i18next';

const ProductInfo = (props) => {
  const { t } = useTranslation('productInfo');
  const { product, currencyType, taxRate } = props;
  let engrave;

  if (product.engravingLines) {
    engrave = product.engravingLines.map((item, index) => {
      const icon = item.icon;
      const line = item[`line${index}`];
      return (
        <div key={index}>
          { icon ? `${t('icon')}: ${icon}` : null }
          {/* note that '' indicates an intentionally empty line we do want to display to user */}
          { (line || line  === '') ? `${t('line')} ${index}: ${line}` : null }
        </div>
      )
    });
  }

  const showSubLabels = { 
    isTrialNoCommitmentOrCredit: Boolean(product.isTrial || (!product.hasCommitmentDays && !product.showCreditedMonths)),
    nonTrialCreditedMonths: Boolean(!product.isTrial && product.showCreditedMonths),
    nonTrialCommittedDays: Boolean(!product.isTrial && product.hasCommitmentDays),
    skuColor: Boolean(product.skuColor),
    description: Boolean(product.description),
    engravingLines: Boolean(product.engravingLines),
    UPC: Boolean(product.UPC)
  }

  return (
    <div className={ styles.orderCartContents } data-test-id={`cart-item-${props.index}`}>
      <div className={ styles.cartColumn }>
        <div data-test-id={`cart-item-${props.index}-name`}>
          { product.name }
        </div>
        <div>
        { Boolean(product.hasMembership) &&
          <div className={ styles.subLabel }>
            { showSubLabels.isTrialNoCommitmentOrCredit &&
              <>
                {
                  product.trialMonths > 1 ? 
                  t('months', { number: product.trialMonths })
                  : t('month', { number: product.trialMonths })
                } &nbsp;
              </> }
            { showSubLabels.nonTrialCreditedMonths &&
              <>
                {t('months', { number: product.creditedMonths })} &nbsp;
              </> }
            { showSubLabels.nonTrialCommittedDays &&
              <>
                { product.hasTrialDays ?
                  t('monthsWithCommitment', {
                    trialMonths: product.trialMonths,
                    commitmentMonths: product.commitmentMonths
                  }) 
                  : t('monthCommitment', {number: product.commitmentMonths})}
              </> }
          </div> }
        { showSubLabels.skuColor &&
          <div className={ styles.subLabel }>
            { product.skuColor }
          </div> }
        { showSubLabels.description &&
          <div className={ styles.subLabel }>
            { product.description }
          </div> }
        { showSubLabels.engravingLines &&
          <div className={`${styles.subLabel}`}>
            { engrave }
          </div> }
        { showSubLabels.UPC &&
          <div className={ styles.subLabel }>
            { t('UPC', { UPC: product.UPC }) }
          </div> }
        </div>
      </div>
      <div className={ styles.cartColumn }>
        { !props.giftView &&
          <> { product.hasPrice ? 
            <div className={ styles.cartItemPrice }>
              {currencyType !== 'EUR' && isVatInclusive(currencyType) ?
                formatVatInclusivePrice(product.amount, currencyType, taxRate) :
                formatPrice(product.amount, currencyType) }
            </div>
            : <div className={ styles.freeText }>
                {t('free')}
              </div>
          } </>
        }
        <div>
          <div className={ styles.quantity }>
            { t('quantity', { quantity: product.quantity }) }
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductInfo;
