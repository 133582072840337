import React from 'react';
import LookUpStripe from './look-up-stripe';
import LookUpOrder from "./look-up-order";

const Status = () => {
  const url_string = window.location.href.toString();
  const isRegionWithNoZipCode = url_string.indexOf('email=') > -1;
  let regionWithNoZipCode_url;

  if (isRegionWithNoZipCode) {
    const email = url_string.split('email=');
    regionWithNoZipCode_url = `${email[0]}email=${encodeURIComponent(email[1])}`;
  }

  const url = new URL(isRegionWithNoZipCode ? regionWithNoZipCode_url : url_string);
  const data = {
    orderId: url.searchParams.get("order"),
    stripeOrder: url.searchParams.get("stripe"),
    orderZip: url.searchParams.get("zip"),
    orderEmail: url.searchParams.get("email"),
    gift: url.searchParams.get("gift")
  };

  const hasStripeOrderDetails = ((data.stripeOrder) && (data.orderZip || data.orderEmail)) || data.gift;

  return (
    <div className="container">
      { hasStripeOrderDetails ?
          <LookUpStripe data={data} />
          : <LookUpOrder data={data} />}
    </div>
  );
}

export default Status;
