import { useTranslation } from "react-i18next";
import {
  DEFAULT_LANGUAGE,
  formatPrice as _formatPrice,
  getCountriesForRegion,
  getRegionFromRequest,
  getTaxInformationForCountry,
  isDisplayedVatInclusive,
} from '@whoop/i18n';
import { getJoinUrl } from '@whoop/web-components';

const apiService = require('../services/api-service').default;
const moment = require('moment');
const config = require('../config');
const { gtagCommand } = require('../services/monitoring');
const { logout, getAuthToken } = require('../services/auth-client');

const hasRegionWithNoZipCodeCustomer = () => {
  const user = getAuthToken();

  return user && user.isRegionWithNoZipCodeCustomer;
};

const getJoinFlowUrl = () => {
  const isProd = config.env === 'production';
  return getJoinUrl(isProd);
};

const grabOrder = (data, callBack, orderError) => {
  const obj = {
    orderId: data.orderId,
    orderZip: data.zip,
    isRegionWithNoZipCode: hasRegionWithNoZipCodeCustomer()
  };

  if (hasRegionWithNoZipCodeCustomer()) {
    obj.orderEmail = data.orderEmail;
  }

  apiService.getOrderLookUp(obj).then((result) => {
    callBack(result);
    gtagCommand('Order-Status', 'confirm', 'Order Look Up - Complete');
    document.documentElement.scrollTop = 0;
  }, (error) => {
    orderError(error.message, obj);
    logout();
  });
};

const disableMobileScroll = () => {
  if (+window.screen.width <= 767) {
    document.body.classList.add('disableScroll');
  }
};

const enableMobileScroll = () => {
  document.body.classList.remove('disableScroll');
};

const getDateParts = (date) => {
  if (date) {
    return {
      month: moment(date, 'YYYY-MM-DD').format('MMMM'),
      date: moment(date, 'YYYY-MM-DD').format('D'),
      extension: moment(date, 'YYYY-MM-DD').format('Do').replace(/\d*/, '')
    };
  }

  return {
    month: null,
    date: null,
    extension: null,
    isNull: true
  }
};

const getVAT = (country) => {
  const taxInfo = getTaxInformationForCountry(country);
  if (!taxInfo) return;
  return `${taxInfo.type.toUpperCase()} (${taxInfo.taxID})`;
};

const isVatInclusive = (currencyType) => {
  return isDisplayedVatInclusive(currencyType.toLowerCase());
};

const shouldShowTaxBreakdown = (currencyType) => {
  return isVatInclusive(currencyType) && !['AUD', 'NZD'].includes(currencyType.toUpperCase());
};

const shouldShowVatInclusivePrices = (currencyType) => {
  return isVatInclusive(currencyType) && !shouldShowTaxBreakdown(currencyType);
}

// update me? pull in from @whoop/i18n if possible to npm i with a new pkg
const formatDate = (date) => {
  return moment(date, 'YYYY-MM-DD').format('MMMM DD, YYYY');
};

const formatPrice = (price, currencyType) => {
  const locale = navigator.language || DEFAULT_LANGUAGE;
  return _formatPrice(price, currencyType.toLowerCase(), { language: locale.slice(0, 2), showCents: true, inCents: false });
};

const getVatInclusiveAmount = (amount, taxRate) => {
  amount = parseFloat(amount);
  const taxAmount = Math.round(taxRate * amount * 100) / 100;
  return amount + taxAmount;
}

const formatVatInclusivePrice = (price, currencyType, taxRate) => {
  price = getVatInclusiveAmount(price, taxRate || 0);
  return formatPrice(price, currencyType);
};

const getShippingAmount = (order, isRegionEU) => {
  if (shouldShowVatInclusivePrices(order.currencyType)) {
    return getVatInclusiveAmount(order.shippingAmount, order.taxRate);
  } else if (isRegionEU) {
    return order.shippingAmount - (order.shippingAmount * order.taxRate);
  }

  return order.shippingAmount;
}

const useSubtotalLines = (order) => {
  const { t } = useTranslation('subtotal');
  const taxRate = order.taxRate || 0;
  const isRegionEU = order.currencyType === 'EUR' && taxRate;
  const vatInclusive = isVatInclusive(order.currencyType);
  const showTaxBreakdown = shouldShowTaxBreakdown(order.currencyType);

  // Add subtotal
  order.subtotal = parseFloat(order.subtotal);
  const subtotalAmount = isRegionEU ? order.subtotal - (order.subtotal * taxRate) : order.subtotal;
  const lines = [];

  if (showTaxBreakdown || !vatInclusive) {
    lines.push({
      label: showTaxBreakdown ? t('totalBeforeVat') : t('subtotal'),
      price: formatPrice(subtotalAmount, order.currencyType),
      style: {marginRight: '-0.12em'}
    })
  } else {
    lines.push({
      label: t('subtotal'),
      price: formatVatInclusivePrice(order.subtotal, order.currencyType, taxRate),
      style: {marginRight: '-0.12em'}
    })
  }

  // Add VAT on subtotal if in a VAT-inclusive region that requires tax to be broken out
  if (showTaxBreakdown) {
    lines.push({
      label: t('vatOnSubtotal'),
      price: formatPrice(order.subtotal * taxRate, order.currencyType),
      style: {marginRight: '-0.12em'}
    });
  }

  // Discounts
  if (order.hasDiscount) {
    const formattedDiscount = isVatInclusive(order.currencyType) && !isRegionEU ? formatVatInclusivePrice(-order.discounts, order.currencyType, taxRate) : formatPrice(-order.discounts, order.currencyType);
    lines.push({
      label: t('discount'),
      price: formattedDiscount,
      style: {marginRight: '-0.12em'}
    });
  }

  // Shipping
  const shippingAmount = getShippingAmount(order, isRegionEU);
  lines.push({
    label: t('shipping'),
    price: order.hasShippingAmount ? formatPrice(shippingAmount, order.currencyType) : t('free'),
    style: { marginRight: '-0.12em', ...(order.hasShippingAmount ? {} : { color: '#FF0026', fontWeight: 'bold' }) }
  });

  // Add VAT on shipping if in a VAT-inclusive region that requires tax to be broken out
  if (showTaxBreakdown) {
    lines.push({
      label: t('vatOnShipping'),
      price: formatPrice(order.shippingAmount * taxRate, order.currencyType),
      style: {marginRight: '-0.12em'}
    });
  }

  // Tax
  if (order.hasSalesTax && !vatInclusive) {
    const vatLabel = getVAT(order.country);
    const taxLabel = vatLabel ? vatLabel : t('tax');
    lines.push({
      label: taxLabel,
      price: formatPrice(order.salesTax, order.currencyType),
      style: {marginRight: '-0.12em'}
    });
  }

  const isReservationPreShipment = order.isReservation && !order.isShipped && order.reservationState === 'reserved';
  const isReservationPaid = order.isReservation && order.reservationState === 'paid';
  // Total/Total due at Shipping
  const totalLabel = isReservationPreShipment ?
      t('totalDueAtShipping')
      : isReservationPaid || !order.isReservation ? t('totalPaid') : t('totalDue');
  const totalAmount = order.isReservation ? order.totalDueAtShipping : order.totalAmount;
  lines.push({
    label: totalLabel,
    price: formatPrice(totalAmount, order.currencyType),
    style: { marginRight: '-0.12em', ...(isReservationPreShipment ? { color: '#969696' } : { fontWeight: 'bold' }) }
  });

  // Total paid for reservations pre-ship
  if (isReservationPreShipment) {
    lines.push({
      label: t('totalPaid'),
      price: formatPrice(order.totalAmount, order.currencyType),
      style: {marginRight: '-0.12em'}
    });
  }

  return lines;
}

const showTaxRegistration = (order) => {
  const currency = order.currencyType;
  const country = order.country;
  const uaeCountries = getCountriesForRegion('AE').map((country) => country.alpha2);
  const saCountries = getCountriesForRegion('SA').map((country) => country.alpha2);
  return uaeCountries.includes(country) ||
    saCountries.includes(country) ||
    (currency === 'USD' && country !== 'US');
};

const getTaxRegistrationNumber = (order) => {
  const country = order.country;
  const taxInformation = getTaxInformationForCountry(country);
  if (!taxInformation) return;
  return taxInformation.taxID;
};

export {
  grabOrder,
  disableMobileScroll,
  enableMobileScroll,
  getDateParts,
  getVAT,
  getJoinFlowUrl,
  hasRegionWithNoZipCodeCustomer,
  formatDate,
  formatPrice,
  formatVatInclusivePrice,
  useSubtotalLines,
  isVatInclusive,
  showTaxRegistration,
  getTaxRegistrationNumber,
};
