import React from "react";
import styles from './thumbnail-select.module.scss';
import { IconButton } from "../IconButton";
import { c } from "../../utils";
import { BasicComponentProps } from "../../types";

export type ThumbnailSelectProps = {
  value?: number;
  onChange?: (index: number) => void;
  showArrowButtons?: boolean;
} & BasicComponentProps;

export const ThumbnailSelect = ({ value=0, onChange, className, children, showArrowButtons, ...props }: ThumbnailSelectProps ) => {
  const childrenArray = React.Children.toArray(children).filter((child) =>
    React.isValidElement(child),
  ) as React.ReactElement[];

  const thumbnailsStyle = {
    '--image-index': value,
    '--image-count': childrenArray.length,
  } as React.CSSProperties;

  const handleChange = (newValue: number) => () => {
    onChange && onChange(newValue)
  }

  return <div className={c(styles.thumbnailContainer, className)}
              {...props}>
    <IconButton
      name='caret_left'
      variant='link'
      disabled={!showArrowButtons || value === 0}
      onClick={handleChange(value - 1)}
    />
    <div className={styles.thumbnails} style={thumbnailsStyle}>
      {childrenArray?.map((child, index) =>
        <a key={index}
           onClick={handleChange(index)}
           className={c(index === value && styles.isSelected)}>
          {child}
        </a>)}
    </div>
    <IconButton
      name='caret_right'
      variant='link'
      disabled={!showArrowButtons || value === childrenArray.length - 1}
      onClick={handleChange(value + 1)}
    />
  </div>
}
