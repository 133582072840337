import React from 'react'
import { RadioInput } from '../../RadioSelectors/RadioInput'
import { RadioInputOption } from 'types/RadioInputs'

export interface OptionSelectorProps {
  value: number
  onChange: (option: number) => void
  name: string
  options: number[] | string[]
}

/**
 * @deprecated Use RadioInput component instead
 */
export const OptionSelector = ({
  value,
  options,
  onChange,
  name,
  ...props
}: OptionSelectorProps) => {
  const radioOptions: RadioInputOption[] = options.map((option, index) => {
    return {
      label: option?.toString(),
      value: index
    }
  })

  return (
    <RadioInput
      name={name}
      options={radioOptions}
      onChange={onChange}
      value={value}
      {...props}
    />
  )
}
