import React  from 'react';
import '../../index.module.scss';
import styles from './status-tag.module.scss';
import { c } from '../../utils';
import { Statuses } from '../../types/Statuses';
import { StatusTagThemes } from '../../types/StatusTagThemes';
import { BasicComponentProps } from "../../types";

export type StatusTagProps = {
    color: Statuses,
    theme?: StatusTagThemes,
} & BasicComponentProps;

const defaultTheme = StatusTagThemes.DEFAULT

export const StatusTag = ({ color, children, theme = defaultTheme, className, ...props }: StatusTagProps) => {
    return (
        <div className={c(
                styles.statusTag,
                styles[`background-color-${color}`],
                `theme-${theme}`,
                className
            )}
            {...props}
        >
            <div className={c(
                    styles.textFormatting,
                    `theme-${theme}`
                )}
            >
                { children }
            </div>
        </div>
    )
}
