export enum ButtonVariants {
  NORMAL = 'normal',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  LINK = 'link',
  SURVEY = 'survey',
  BLUE = 'blue',
}

export enum ButtonSizes {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large'
}

export enum ButtonThemes {
  DEFAULT = 'default',
  ENTERPRISE = 'enterprise',
  DARK = 'dark',
  JOIN_FLOW = 'join-flow'
}
