import React, {useEffect, useState} from 'react'
import { Region, RegionCurrencyDetails } from '@whoop/i18n';
import {Dropdown} from "../../Dropdown";
import {c} from "../../../utils";
import styles from "./currencySelector.module.scss"
import {CurrencySelectorThemes} from "../../../types/CurrencySelectorThemes";
import {DropdownThemes} from "../../../types/Dropdown";

export interface CurrencySelectorProps extends React.HTMLAttributes<HTMLDivElement> {
  onOptionSelected: (region: string) => void
  region?: Region
  theme?: CurrencySelectorThemes
}

export function CurrencySelector({
  onOptionSelected,
  region,
  theme = CurrencySelectorThemes.CURRENCY,
  className,
  ...props
}: CurrencySelectorProps) {
  const [selectedOption, setSelectedOption] = useState<string>()

  useEffect(() => {
    if (!selectedOption) {
      const _regionCurrencyDetails = RegionCurrencyDetails.find((details) => details.region === region)

      if (_regionCurrencyDetails) {
        setSelectedOption(`${_regionCurrencyDetails.displayName} (${_regionCurrencyDetails.currency})`)
      } else {
        setSelectedOption(`${RegionCurrencyDetails[0].displayName} (${RegionCurrencyDetails[0].currency})`)
      }
    }
  }, [region])

  const _onOptionSelected = (option: string) => {
    const _regionCurrencyDetails = RegionCurrencyDetails.find((details) => `${details.displayName} (${details.currency})` === option);

    if (_regionCurrencyDetails) {
      setSelectedOption(option)
      onOptionSelected(_regionCurrencyDetails.region)
    }
  }

  return (
    <Dropdown
      options={RegionCurrencyDetails.map((region) => `${region.displayName} (${region.currency})`)}
      onOptionSelected={(option) => _onOptionSelected(option)}
      selectedOption={selectedOption}
      theme={theme === CurrencySelectorThemes.CURRENCY ? DropdownThemes.CURRENCY : DropdownThemes.CURRENCY_DARK}
      className={c(styles.dropdownWrapper, className)}
      {...props}
    />
  )
}
