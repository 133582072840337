import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Button, Subtotal } from '@whoop/web-components';
import apiService from '../services/api-service';
import { segmentTrack } from '../services/monitoring';
import styles from '../css/update-payment-method.module.css';
import { useSubtotalLines } from '../services/support';
import {useTranslation} from "react-i18next";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      iconColor: "#666EE8",
      color: "rgba(0, 0, 0, 1)",
      fontWeight: 400,
      fontSize: "16px",
      fontFamily: "ProximaNova",

      "::placeholder": {
        color: "rgba(0, 0, 0, 0.3)",
        fontFamily: "ProximaNova-Light"
      },

      ":focus": {
        color: "black"
      }
    }
  }
};

const UpdatePaymentMethod = ({ order, onSubmitSuccess }) => {
  const { t } = useTranslation('updatePaymentMethod');
  const [isLoading, setIsLoading] = useState(false);
  const [cardError, setCardError] = useState(null);
  const [submitError, setSubmitError] = useState(null);

  const stripe = useStripe();
  const elements = useElements();
  const subtotalLines = useSubtotalLines(order);

  const handleCardChange = ({ error }) => {
    setSubmitError(null);

    if (error) {
      setCardError(error.message);
    } else {
      setCardError(null);
    }
  };

  const handleUpdatePayment = async e => {
    e.preventDefault();
    setCardError(null);
    setSubmitError(null);
    setIsLoading(true);

    segmentTrack('Reservation Payment Page', {
      category: 'Pressed Update Payment',
      orderId: order.stripeOrderId
    });

    if (!stripe || !elements) {
      setIsLoading(false);
      return;
    }

    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);

    if (result.error) {
      if (result.error.type === 'validation_error') {
        setCardError(result.error.message);
      } else {
        setSubmitError(result.error.message);
      }

      setIsLoading(false);
      return;
    }

    try {
      const submitResult = await apiService.chargeReservationOrder({
        stripeOrderId: order.stripeOrderId,
        tokenId: result.token.id,
        shipZip: order.zip
      });

      if (submitResult.status === 'failure') {
        const trimmedDescription = submitResult.description.split(';')[0];
        setSubmitError(trimmedDescription);
        setIsLoading(false);
      } else if (submitResult.status === 'success') {
        setIsLoading(false);
        onSubmitSuccess();
      }
    } catch (error) {
      setSubmitError(error.message);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className={styles.title}>Update Payment Method</div>
      <form className={styles.updatePaymentForm} onSubmit={handleUpdatePayment}>
        <div className={styles.cardIconSection}>
          <div className={`${styles.cardIcon} ${styles.amexIcon}`}></div>
          <div className={`${styles.cardIcon} ${styles.mastercardIcon}`}></div>
          <div className={`${styles.cardIcon} ${styles.visaIcon}`}></div>
          <div className={`${styles.cardIcon} ${styles.discoverIcon}`}></div>
          <div className={`${styles.cardIcon} ${styles.dinersIcon}`}></div>
        </div>
        <CardElement className={styles.creditCard} options={CARD_ELEMENT_OPTIONS} onChange={handleCardChange} />
        {cardError && <div className={styles.error}>{cardError}</div>}
        {submitError && <div className={styles.error}>{submitError}</div>}
        <hr className={styles.divider} />
        <div className={styles.subtotalSection}>
          <Subtotal lines={subtotalLines} />
        </div>
        <Button
          type="submit"
          className={styles.submitButton}
          variant="primary"
          theme="default"
          label={t('updatePayment')}
          disabled={isLoading || cardError || submitError}
        />
      </form>
    </div>
  );
};

UpdatePaymentMethod.propTypes = {
  order: PropTypes.shape({
    stripeOrderId: PropTypes.string.isRequired
  }).isRequired,
  onSubmitSuccess: PropTypes.func
};

UpdatePaymentMethod.defaultProps = {
  onSubmitSuccess: () => {}
};

export default UpdatePaymentMethod;