import React, {ReactNode, useEffect, useState} from "react";
import { Slideshow } from '../../Slideshow';
import { ProductHeader } from '../ProductHeader';
import styles from './product-details-grid.module.scss'
import { ThumbnailSelect } from "../../ThumbnailSelect";
import {Accordion, AccordionSection} from "../../Accordion";
import { c } from "../../../utils";
import { Badge } from "../../Badge";
import { AnalyticsFunction } from "../../../types";

export interface ProductDetailsGridProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  subTitle?: string;
  price: string;
  originalPrice?: string;
  isProDiscount?: boolean;
  withWhoopProPrice?: string;
  isFancy?: boolean;
  media?: React.ReactNode[];
  accordions?: AccordionSection[];
  previewClassName?: string;
  infoClassName?: string;
  scrollParentRef?: React.RefObject<HTMLElement>;
  onImageChange?: (index: number) => any;
  noImageScroll?: boolean;
  imageMessage?: string;
  description?: string;
  footer?: React.ReactNode;
  reviewStars?: React.ReactNode;
  onAnalyticsEvent?: AnalyticsFunction;
}

const SCROLL_THRESHOLD = 2;

export const ProductDetailsGrid = (
  {
    children,
    media,
    title,
    price,
    originalPrice,
    withWhoopProPrice,
    isProDiscount,
    isFancy,
    accordions,
    previewClassName,
    infoClassName,
    className,
    scrollParentRef,
    onImageChange,
    noImageScroll,
    subTitle,
    imageMessage,
    description,
    reviewStars,
    footer,
    onAnalyticsEvent,
    ...props
  }: ProductDetailsGridProps): JSX.Element => {
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);
  const [scroll, setScroll] = useState(false);
  const shouldScroll = !noImageScroll && scroll;

  useEffect(() => {
    onImageChange && onImageChange(selectedImageIndex);
  }, [selectedImageIndex])

  useEffect(() => {
      if (scrollParentRef?.current) {
          const ele: HTMLElement = scrollParentRef?.current;
          ele.addEventListener("scroll", () => {
              setScroll(ele.scrollTop > SCROLL_THRESHOLD)
          })
          setScroll(ele.scrollTop > SCROLL_THRESHOLD)
      } else {
          const ele: Window & typeof globalThis = window
          ele.addEventListener("scroll", () => {
              setScroll(ele.scrollY > SCROLL_THRESHOLD)
          })
          setScroll(ele.scrollY > SCROLL_THRESHOLD)
      }
      return () => { //on unmount
          if (scrollParentRef?.current) {
              const ele: HTMLElement = scrollParentRef?.current
              ele.removeEventListener("scroll", () => {
                  setScroll(ele.scrollTop > SCROLL_THRESHOLD)
              })
              setScroll(ele.scrollTop > SCROLL_THRESHOLD)
          }else{
              const ele: Window & typeof globalThis = window
              ele.removeEventListener("scroll", () => {
                  setScroll(ele.scrollY > SCROLL_THRESHOLD)
              })
              setScroll(ele.scrollY > SCROLL_THRESHOLD)
          }
      }

  }, [scrollParentRef?.current]);

  return <div
    className={c('whoop-ui', styles.productLayout, className)}
    {...props}>
    <div className={c(styles.productPreview,
      previewClassName,
      styles.productPreviewScroll,
      noImageScroll && styles.noScroll,
      shouldScroll && styles.scrolled
    )}>
      <div className={c(styles.scrollHide, styles.unhidden)}>
        <Slideshow
          className={c(styles.slideshow, shouldScroll && styles.scrollImage )}
          value={selectedImageIndex}
          onChange={setSelectedImageIndex}
          parentSizing={true}
          prevNextButtons={true}
          overlay={imageMessage && <Badge className={styles.overlayBadge} label={imageMessage}/>}
        >{media}</Slideshow>
        <ThumbnailSelect
          value={selectedImageIndex}
          onChange={setSelectedImageIndex}
          showArrowButtons={true}>{media}</ThumbnailSelect>
      </div>
        {accordions && <Accordion sections={accordions} className={styles.desktopOnly} />}
    </div>

    <div className={c(styles.productInfo, infoClassName)}>
      <ProductHeader
        title={title}
        subTitle={subTitle}
        price={price}
        originalPrice={originalPrice}
        withWhoopProPrice={withWhoopProPrice}
        isProDiscount={isProDiscount}
        fancy={isFancy}
        reviewStars={reviewStars}
      />
      {children}
      {description &&
        <div className={styles.productDescription}
             dangerouslySetInnerHTML={{ __html: description }}
        />}
      {footer}
    </div>
    {accordions && <Accordion
      sections={accordions}
      className={styles.mobileOnly}
      onSectionOpen={({ id, title }) => {
        onAnalyticsEvent && onAnalyticsEvent('Open Product Accordion', { id, title })
      }}
      onSectionClose={({ id, title }) => {
        onAnalyticsEvent && onAnalyticsEvent('Close Product Accordion', { id, title })
      }}
    />}
  </div>
};
