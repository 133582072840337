import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Status from './components/status';
import ReservationStatus from './components/reservation-status';
import Header from './components/header';
import Footer from './components/footer';
import styles from './css/app.module.css';
import { enableDebugger } from './services/experiment';

import '@whoop/web-components/dist/index.css';
import config from './config';

if (config.env !== 'production') {
  enableDebugger();
}

const App = () => (
  <div className={styles.orderStatus}>
    <div className={styles.bgContainer} />
    <div className={styles.container}>
      <Header />
      <Switch>
        <Route
          exact
          path="/orderlookup"
          render={() => <Redirect to="/" />}
        />
        <Route
          exact
          path="/updatereservation"
          render={ReservationStatus}
        />
        <Route
          exact
          path="/"
          render={Status}
        />
        <Route
          path="*"
          render={() => <Redirect to="/" />}
        />
      </Switch>
    </div>
    <Footer />
  </div>
);

export default App;
