import React from 'react';
import styles from '../css/input-field.module.css';
import { getCountriesForRegion, getRegionFromRequest } from '@whoop/i18n';

const CountrySelection = (props) => {
  const { currency, country, name, inputRef } = props;
  const region = getRegionFromRequest(country);
  const countries = getCountriesForRegion(region);
  const homeLand = countries.find((curCountry) => country === curCountry.alpha2);

  return (
    <div data-test-id="country-select" className={ styles.inputField }>
      <input className={ styles.input } type="text" value={homeLand.name} disabled={true} />
      <input type="hidden" value={homeLand.alpha2} name={name} disabled={true} ref={inputRef} />
    </div>
  );
};

export default CountrySelection;
