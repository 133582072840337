import { useEffect, useState } from 'react'
import styles from '../responsive.mixin.scss'

export enum Breakpoint {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl'
}

const BREAKPOINTS = {
  [Breakpoint.XL]: styles.xl,
  [Breakpoint.LG]: styles.lg,
  [Breakpoint.MD]: styles.md,
  [Breakpoint.SM]: styles.sm,
  [Breakpoint.XS]: styles.xs
}

// How to write hooks for SSR
// https://observablehq.com/@werehamster/avoiding-hydration-mismatch-when-using-react-hooks
export function useLessThanBreakpoint(breakpoint: Breakpoint): boolean {
  const [matches, setMatches] = useState<boolean>(null)

  const less = (breakpoint: Breakpoint) =>
    `only screen and (max-width: calc(${BREAKPOINTS[breakpoint]} - 1px))`


  useEffect(() => {
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia
    // matchMedia is an api that takes an media query and returns a boolean if the media query is true/false
    const matchMedia = window.matchMedia(less(breakpoint))

    const listener = () => setMatches(!!matchMedia.matches)
    matchMedia.addEventListener('change', listener)
    
    // Triggered at the first client-side load and if query changes
    listener()

    return () => matchMedia.removeEventListener('change', listener)
  }, [breakpoint])
  
  return matches
}
