import React from 'react';
import { Icon } from '../Icon';
import { classes } from '../../utils';
import styles from './payment-method-icons.module.scss'
import { PaymentMethod, DefaultPaymentMethods } from '../../types/Payments';

export interface PaymentMethodIconsProps extends React.HTMLAttributes<HTMLDivElement> {
  options?: PaymentMethod[];
}

export const PaymentMethodIcons = ({
  className,
  options = DefaultPaymentMethods,
  ...props
}: PaymentMethodIconsProps): JSX.Element => {
  return (
    <div
      className={classes(styles.paymentMethodIcons, className)}
      {...props}>
      { options?.map((paymentMethod) => { 
        return <Icon key={paymentMethod} name={paymentMethod} />
      }) }
    </div>
  );
};
