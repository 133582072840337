import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './gift-message-section.module.scss';
import { Alert } from '../../Alert';
import { c } from '../../../utils';
import { Trans } from 'react-i18next';
import { useTranslation, importTranslations } from '../../../utils/i18n';
import ArrowRight from '../../../icons/Navigation/ic_arrow_right.svg';
import CaretRight from '../../../icons/Navigation/ic_caret_right.svg';
import { GiftMessageSectionThemes } from '../../../types/GiftMessageSectionThemes';
import { StatusTagThemes } from '../../../types/StatusTagThemes';
importTranslations('giftMessageSection', require.context('./languages/', true));

export const GiftMessageSection = ({ canEdit, gifteeView, giftMessage, giftReceiptUrl, openGiftMessageModal, purchaserEmail, theme = GiftMessageSectionThemes.DEFAULT, ...props }) => {
  const { t } = useTranslation('giftMessageSection');
  const [deliveryTime, setDeliveryTime] = useState('');
  const [recipientEmail, setRecipientEmail] = useState('');

  useEffect(() => {
    if (giftMessage?.delivery_time) {
      const date = new Date(giftMessage?.delivery_time);
      const dateString = date.toLocaleDateString('default', { month: 'short', day: 'numeric', year: 'numeric' });
      setDeliveryTime(dateString);
    }
  }, [giftMessage]);

  useEffect(() => {
    if (giftMessage?.giftee_email) {
      setRecipientEmail(giftMessage.giftee_email);
    }
    else if (purchaserEmail) {
      setRecipientEmail(purchaserEmail);
    }
    else {
      setRecipientEmail(t('you'));
    }
  }, [giftMessage, purchaserEmail]);

  const getGiftReceiptDeliveryText = () => {
    if (giftMessage?.delivered_at) {
      return (
        <Trans t={t} i18nKey="giftReceiptWasSent">
          Your gift receipt was <span className={styles.giftMessageBold}>sent</span>.
        </Trans>
      );
    }

    if (giftMessage?.delivery_time) {
      return (
        <Trans t={t} i18nKey="giftReceiptWillBeSentOn" deliveryTime={deliveryTime}>
          Your gift receipt will be sent on <span className={styles.giftMessageBold}>{{ deliveryTime }}</span>.
        </Trans>
      );
    }

    return (
      <Trans t={t} i18nKey="giftReceiptWillBeSentImmediately">
        Your gift receipt will be sent <span className={styles.giftMessageBold}>immediately</span>.
      </Trans>
    );
  };

  return (
    <div className={c(styles.giftMessageSection, 'whoop-ui', `theme-${theme}`)}>
      {!giftMessage
        ? (
          <Alert
            type={theme === GiftMessageSectionThemes.JOIN_FLOW ? 'neutral' : 'info'}
            iconName='gift'
            onClick={openGiftMessageModal}
            className={styles.addGiftMessageAlert}
            theme={theme === GiftMessageSectionThemes.JOIN_FLOW ? StatusTagThemes.JOIN_FLOW : StatusTagThemes.DEFAULT}
          >
            <div className={styles.alertContent}>
              <div>
                <div className={styles.alertTitle}>{t('sendGiftReceipt')}</div>
                <div className={styles.alertBody} data-testId='gift-message-section-content-body'>{theme === GiftMessageSectionThemes.JOIN_FLOW ? t('joinFlowBody') : t('sendPersonalizedGiftMessage')}</div>
              </div>
              <div className={styles.alertArrow}>{theme === GiftMessageSectionThemes.JOIN_FLOW ? <CaretRight data-testId='gift-message-section-caret-icon' /> : <ArrowRight />}</div>
            </div>
          </Alert>
        ) : (
          <div className={styles.giftMessageContainer}>
            {gifteeView
              ? (
                <div className={c(styles.giftMessageHeader, styles.gifteeHeader)}>
                  {t('giftMessage')}
                </div>
              ) : (
                <div className={styles.giftMessageHeader}>
                  {getGiftReceiptDeliveryText()}
                  {giftReceiptUrl &&
                    <span> <a href={giftReceiptUrl} target='_blank' rel='noopener noreferrer'>{t('viewGiftReceipt')}</a></span>}
                </div>
              )}
            <div className={styles.giftMessageContent}>
              {!gifteeView &&
                <div className={styles.topLine}>
                  <div className={styles.title}>{t('giftMessage')}</div>
                  {canEdit && <div className={styles.edit} onClick={openGiftMessageModal}>{t('edit')}</div>}
                </div>}
              <div className={styles.giftMessageContents}>
                {!gifteeView && <div className={c(styles.recipientEmail, styles.spacedLine)}>{t('emailToRecipient', { recipientEmail })}</div>}
                <div>{t('toGiftee', { name: giftMessage.giftee_name })}</div>
                <div className={styles.spacedLine}>{t('fromGifter', { name: giftMessage.gifter_name })}</div>
                <div>{giftMessage.gift_message}</div>
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

GiftMessageSection.propTypes = {
  canEdit: PropTypes.bool,
  gifteeView: PropTypes.bool,
  giftMessage: PropTypes.object,
  giftReceiptUrl: PropTypes.string,
  openGiftMessageModal: PropTypes.func,
  purchaserEmail: PropTypes.string
};
