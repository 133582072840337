import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import '../../index.module.scss'
import styles from './input.module.scss'
import { c } from '../../utils'

/**
 * Input component
 */
export const Input = React.forwardRef(
  (
    {
      className,
      style,
      label,
      placeholder,
      type = 'text',
      subText,
      subTextStyle,
      subTextAction,
      name,
      required,
      disabled,
      error,
      onChange = () => {},
      value = '',
      useExternalState,
      onFocus,
      onBlur,
      onKeyDown,
      inputRef,
      theme,
      onWheel,
      ...props
    },
    ref
  ) => {
    const [focused, setFocused] = useState(false)
    const [valueState, setValue] = useState(value)
    const actualValue = useExternalState ? value : valueState
    // set value when parent updates value
    useEffect(() => setValue(value), [value])

    const handleChange = (event) => {
      const text = event.target.value
      setValue(text)
      onChange(text)
    }

    const _onFocus = (event) => {
      setFocused(true)
      onFocus && onFocus(event)
    }
    const _onBlur = (event) => {
      setFocused(false)
      onBlur && onBlur(event)
    }

    const justPlaceholder = !!placeholder && !label
    const hasBoth = !!placeholder && !!label
    const fallbackName = label || placeholder

    // --- Render ---
    return (
      <div
        className={c(
          className,
          styles.input,
          focused && styles.focused,
          error && styles.error,
          !justPlaceholder &&
            (focused || actualValue || hasBoth) &&
            styles.active,
          justPlaceholder && styles.justPlaceholder,
          theme !== 'default' && `theme-${theme}`
        )}
        style={style}
      >
        <fieldset>
          {label && (
            <legend>
              {label}
              {required && <span>*</span>}
            </legend>
          )}
        </fieldset>
        {label && (
          <label>
            {label}
            {required && <span>*</span>}
          </label>
        )}
        <input
          type={type}
          value={actualValue}
          name={name || fallbackName}
          aria-label={props['aria-label'] || fallbackName}
          onChange={handleChange}
          onFocus={_onFocus}
          onBlur={_onBlur}
          onKeyDown={onKeyDown}
          disabled={disabled}
          required={required}
          placeholder={placeholder}
          ref={inputRef || ref}
          onWheel={onWheel}
          {...props}
        />
        {subText && (
          <span className={c(styles.subText, !!subTextAction && styles.clickable)} style={subTextStyle} onClick={subTextAction} data-testid='input-subtext'>
            {subText}
          </span>
        )}
      </div>
    )
  }
)

Input.propTypes = {
  /**
   * Text label, acts as placeholder if none is provided
   */
  label: PropTypes.string,
  /**
   * Placeholder of the input
   */
  placeholder: PropTypes.string,
  /**
   * Name of input
   */
  name: PropTypes.string,
  /**
   * Type of input. Must be "text"(default) or "password"
   */
  type: PropTypes.oneOf(['text', 'password']),
  /**
   * Whether this text field is required in a form
   */
  required: PropTypes.bool,
  /**
   * Whether this text field is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Whether this text field is in error state
   */
  error: PropTypes.bool,
  /**
   * Text label & placeholder
   */
  subText: PropTypes.string,
  /**
   * Optional onChange handler that is called whenever Input is updated
   */
  onChange: PropTypes.func,
  /**
   * Optional flag to either use it's internal state or use the state provided in value
   */
  useExternalState: PropTypes.bool,
  /**
   * Value of the text input (only used as initial value, or if used fully if useExternalState is true
   */
  value: PropTypes.string,
  /**
   * Optional color theme to use
   */
  theme: PropTypes.oneOf(['default', 'dark']),
  /**
   * For any input with type number, onWheel function can prevent value change on scroll
   */
  onWheel: PropTypes.func,
}

