import React from 'react';
import {c} from "../../utils";
import styles from './Badge.module.scss';
import { BasicComponentProps } from "../../types";

export enum BadgeColors {
  GRAY = 'gray',
  PRO = 'pro',
  BLACK = 'black',
  TRANSPARENT = 'transparent',
  BLUE = 'blue'
}

export type BadgeProps = {
  color?: BadgeColors;
  label?: string;
} & BasicComponentProps;

export function Badge(
  {
    className,
    color=BadgeColors.BLACK,
    label,
    children,
    ...props
  }: BadgeProps) {
  return (
    <div
      className={c(styles.badge, color && styles[color], className)}
      {...props}
    >
      {children || label}
    </div>
  )
}
