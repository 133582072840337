import React from 'react';
import styles from '../css/shipping-info.module.css';
import {useTranslation} from "react-i18next";

const PartialShipments = ({ orderItems = {} }) => {
  const { t } = useTranslation('partialShipments')
  const shipmentMap = orderItems.reduce((_shipments, orderItem) => {
    const shipmentKey = orderItem.shipDate;
    if (_shipments[shipmentKey]) {
      _shipments[shipmentKey].push(orderItem);
    } else {
      _shipments[shipmentKey] = [orderItem];
    }
    return _shipments;
  }, {});

  const sortedShipmentKeys = Object.keys(shipmentMap).sort((a, b) => {
    if (!a || a === 'undefined') return 1;
    if (!b || b === 'undefined') return -1;
    return new Date(b) - new Date(a);
  });

  const getHeader = (shipment) => {
    const shipDate = shipment[0].shipDate;
    if (!shipDate) {
      return t('checkBackLater');
    }
    const date = new Date(shipDate);
    return t('shippedOn', { date });
  };

  return (
    <div className={ styles.partialShipmentContainer }>
      { sortedShipmentKeys.map((key, idx) => (
        <div className={ styles.partialShipment } key={ key }>
          <div className={ styles.partialShipmentHeader }>
            <span className={ styles.partialShipmentLabel }>{ t('shipmentNumber', { number: idx + 1 }) }</span>
            <span>{ getHeader(shipmentMap[key]) }</span>
          </div>
          <div>
            {
              shipmentMap[key].map((item) => (
                <div key={ item.id }>
                  <div className={ styles.partialShipmentItem }>{ item.name }</div>
                </div>
              ))
            }
          </div>
        </div>
      ))}
    </div>
  )
};

export default PartialShipments;
