/* global HTMLDivElement, Partial */
import React, { useRef, useState } from 'react';
import { c } from '../../../utils';
import styles from './accordion.module.scss';
import { AccordionThemes } from '../../../types/Accordions';
import { BasicComponentProps } from '../../../types';
import CaretDownIcon from '../../../icons/Navigation/ic_caret_down.svg';

export type AccordionV2Section = {
  index?: number;
  title: string;
  content: React.ReactNode;
}

export type AccordionV2Props = {
  sections: AccordionV2Section[];
  onSectionOpen?: (section: Partial<AccordionV2Section>) => void;
  onSectionClose?: (section: Partial<AccordionV2Section>) => void;
  theme?: AccordionThemes;
  secondary?: boolean;
} & BasicComponentProps;

export type AccordionContentProps = {
  isSectionOpen: boolean;
  title: string;
  content: React.ReactNode;
} & BasicComponentProps;

const AccordionContent = ({ isSectionOpen, title, content }: AccordionContentProps) => {
  const myRef = useRef<HTMLDivElement>();
  return (
    <div
      className={c(
        styles.collapsibleInner,
        isSectionOpen && styles.expanded,
      )}
      style={{ maxHeight: `${isSectionOpen ? myRef.current?.clientHeight : '0'}px`, height: myRef.current?.clientHeight }}
      data-testid="section-content"
    >
      <div className={styles.collapsibleContent} id={title} ref={myRef}>
        {content}
      </div>
    </div>
  );
};

export const AccordionV2 = ({ sections, onSectionOpen, onSectionClose, theme = AccordionThemes.DEFAULT, className, secondary = false, ...props }: AccordionV2Props) => {
  const [openSectionId, setOpenSectionId] = useState<number>();

  function isSectionOpen(pageId: number) {
    return openSectionId === pageId;
  }

  function toggleSection(index: number, title: string, content: React.ReactNode) {
    if (openSectionId === index) {
      setOpenSectionId(undefined);
      onSectionClose && onSectionClose({ index, title, content });
    }
    else {
      setOpenSectionId(index);
      onSectionOpen && onSectionOpen({ index, title, content });
    }
  }

  return (
    <div
      className={c(styles.accordion, theme !== AccordionThemes.DEFAULT && `theme-${theme}`, className)}
      {...props}
    >
      <div className={styles.productTabs}>
        {sections?.map(({ title, content, ...sectionProps }, index) => (
          <section
            className={c(styles.collapsible, secondary ? styles.secondary : styles.primary)}
            key={index}
            onClick={() => toggleSection(index, title, content)}
            aria-expanded={isSectionOpen(index)}
            {...sectionProps}>
            <span
              className={c(styles.collapsibleButton)}
              data-testid="section-title"
            >
              {title}
              <CaretDownIcon className={c(styles.arrowIcon)} />
            </span>
            <AccordionContent isSectionOpen={isSectionOpen(index)} title={title} content={content} />
          </section>
        ))}
      </div>
    </div>
  );
};
