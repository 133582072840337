import React from 'react';
import { Icon } from '../Icon';
import styles from './chip.module.scss';
import { classes } from '../../utils';

export interface ChipProps {
  text: string;
  onClick: () => void;
  editing: boolean;
  readOnly: boolean;
  iconName?: string;
  iconClasses?: string;
  rootClasses?: string;
  labelClasses?: string;
}

export const Chip = ({ text, onClick, editing, readOnly, iconName, iconClasses, rootClasses, labelClasses }: ChipProps) => {
  return (
    <div className={classes(styles.chipRoot, rootClasses)}>
      <span className={classes(styles.chipLabel, labelClasses)}>{text}</span>
      {!readOnly && editing &&
        <div className={styles.closeButton} onClick={onClick}>
          <Icon name={iconName || 'cross'} className={classes(styles.closeSvg, iconClasses)} />
        </div>}
    </div>
  );
};
