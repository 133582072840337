import React from 'react'
import { classes } from '../../utils'
import {
  ColAttributes,
  ColumnSizeType,
  LayoutProps,
  OffsetType,
  ViewPortSizeType,
  createProps
} from '../../utils/layoutUtils'
import styles from './layout.module.scss'

export interface ColProps extends LayoutProps {
  xl?: ColumnSizeType
  lg?: ColumnSizeType
  md?: ColumnSizeType
  sm?: ColumnSizeType
  xs?: ColumnSizeType
  xlOffset?: OffsetType
  lgOffset?: OffsetType
  mdOffset?: OffsetType
  smOffset?: OffsetType
  xsOffset?: OffsetType
  first?: ViewPortSizeType
  last?: ViewPortSizeType
}

export function Col({ ...props }: ColProps) {
  const classMap = {
    xs: 'col-xs',
    sm: 'col-sm',
    md: 'col-md',
    lg: 'col-lg',
    xl: 'col-xl',
    xsOffset: 'col-xs-offset',
    smOffset: 'col-sm-offset',
    mdOffset: 'col-md-offset',
    lgOffset: 'col-lg-offset',
    xlOffset: 'col-xl-offset'
  }

  const getColClassNames = (p: ColProps) => {
    const getStyle = (key: ColAttributes) => {
      if (Number.isInteger(p[key])) {
        return `${classMap[key]}-${p[key]}`
      }
      if (p[key]) {
        return classMap[key]
      }
      return `${classMap[key]}-hidden`
    }

    return classes(
      props.className,
      p.first && styles[`first-${p.first}`],
      p.last && styles[`last-${p.last}`],
      ...Object.keys(props)
        .filter((key: ColAttributes) => key && classMap[key])
        .map((key: ColAttributes) => styles[getStyle(key)])
    )
  }

  return React.createElement('div', createProps(props, getColClassNames(props)))
}
