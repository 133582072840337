import React from 'react';
import styles from '../css/input-field.module.css';

const InputField = (props) => {
  const { type, name, placeholder, inputRef, handleEnterKey, testId } = props;

  return (
    <div className={ styles.inputField }>
      <input className={ styles.input }
             type={type}
             name={name}
             placeholder={placeholder}
             ref={inputRef}
             data-test-id={testId}
             onKeyUp={handleEnterKey} />
    </div>
  );
}

export default InputField;
