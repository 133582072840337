import config from '../config';
import amplitude from 'amplitude-js';

const isStageProd = config.env !== 'localhost';
const isProd = config.env === 'production';

const getKey = () => {
  const qaKey = "UA-64891632-5";
  const prodKey = "UA-64891632-6";

  return isProd ? prodKey : qaKey;
};

const initDataDogLogs = () => {
  if (isStageProd && window.DD_LOGS) {
    window.DD_LOGS.init({
      clientToken: 'pubd4b75fa4b40c7567cd4ea87d18a3916e',
      forwardErrorsToLogs: true,
    });
  }
};

const initDataDogRum = () => {
  if (isStageProd && window.DD_RUM) {
    window.DD_RUM.init({
      clientToken: 'pubfaffa7cf646c7a1ab5787531f7831c7f',
      applicationId: '60b4220f-df5b-41c0-afbb-9aff76e9216e',
    });
  }
};

export const dataDogLog = (name, data) => {
  if (isStageProd && window.DD_LOGS) {
    window.DD_LOGS.logger.error('Order Status: Failed ' + name, {
      name: name,
      orderId: data && data.orderId,
      orderZip: data && data.zip,
      orderEmail: data && data.email,
      stripeOrderId: data && data.stripeOrderId
    });
  }
};

export const gtagCommand = (category, action, label) => {
  if (isStageProd && window.gtag) {
    window.gtag('event', action, {
      'event_category': category,
      'event_label': label
    });
  }
};

export const gaCommand = (command, args) => {
  if (isStageProd && window.ga) {
    args.unshift(command);
    window.ga.apply(this, args);
  }
};

// Segment Analytics
var getSegmentKey = function () {
  var qaKey = "G8NLMTz8Y8ZdOxSOqSzRDsdxRF2VNTm4";
  var prodKey = "EYOZJLvCAv9Vl1Ku6oqC2meZ9QR3G7pO";
  return isProd ? prodKey : qaKey;
};

// Track a user in segment
export const segmentIdentify = function (userId, traits, options, callback) {
  if (window.analytics) {
    window.analytics.identify(userId, traits, options, callback);
  }
};

// Track an event in segment
export const segmentTrack = function (event, properties, options, callback) {
  if (window.analytics) {
    window.analytics.track(event, properties, options, callback);
  }
};

// Track a page view in segment
export const segmentPage = function (category, name, properties, options, callback) {
  if (window.analytics) {
    window.analytics.page(category, name, properties, options, callback);
  }
};

// Amplitude tracking
const initAmplitude = () => {
  amplitude.getInstance().init(config.amplitudeKey);
};

export const logAmplitudeEvent = (eventType, eventProperties) => {
  amplitude.getInstance().logEvent(eventType, eventProperties);
};

export const setAmplitudeUserProperties = (properties) => {
  amplitude.getInstance().setUserProperties(properties);
};

export const initMonitoring = () => {
  initDataDogLogs();
  initDataDogRum();
  initAmplitude();

  window.gtag("js", new Date());
  window.gtag("config", getKey());

  if (window.analytics) {
    window.analytics.load(getSegmentKey());
  }
};
