import styles from './referrer-section.module.scss'
import React from 'react'
import { c } from '../../../utils'

export interface ReferrerSectionProps
  extends React.HTMLAttributes<HTMLDivElement> {
  image: string
  title: string
  subTitle?: string
}

export function ReferrerSection({
  image,
  title,
  subTitle,
  className,
  ...props
}: ReferrerSectionProps) {
  return (
    <div className={c(className, styles.referrerSection)} {...props}>
      <img
        className={styles.referrerImage}
        src={image}
        alt={title}
        height={48}
        width={48}
      />
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        {subTitle && <div>{subTitle}</div>}
      </div>
    </div>
  )
}

export default ReferrerSection
