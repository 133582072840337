import React from 'react';
import { FieldValues, SubmitHandler, useForm, FormProvider, UseFormReturn } from 'react-hook-form';
import { BasicComponentProps } from "../../types";

type ManagedFormProps = {
  onSubmit: SubmitHandler<FieldValues>;
} & BasicComponentProps;

const ManagedForm = ({ children, onSubmit, ...props }: ManagedFormProps) => {
  const methods = useForm();

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} {...props}>
        {children}
      </form>
    </FormProvider>
  );
};

interface UnmanagedFormProps extends ManagedFormProps {
  methods: UseFormReturn<FieldValues, any>;
}

const UnmanagedForm = ({ children, methods, onSubmit, ...props }: UnmanagedFormProps) => {
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} {...props}>
        {children}
      </form>
    </FormProvider>
  );
};

export type FormProps = ManagedFormProps | UnmanagedFormProps;

export const Form = (props: FormProps) => {
  if ('methods' in props) return <UnmanagedForm {...props} />;
  return <ManagedForm {...props} />;
};
