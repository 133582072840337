import { MediaItem } from "../../../types/Products";
import { BasicComponentProps } from "../../../types";
import styles from './product-media.module.scss'
import React from "react";

export const ProductMedia = ({ url, id, type, ...props }: MediaItem & BasicComponentProps) => {
  if (type === "video") {
    return <video autoPlay loop playsInline disableRemotePlayback muted>
      <source src={url} type="video/mp4"/>
    </video>;
  }
  return <img src={url}
              alt={`product-img-${id}`}
              className={styles.productImage}/>;
};
