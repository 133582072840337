import React from 'react';
import PropTypes from 'prop-types';
import '../../index.module.scss';
import styles from './footer.module.scss';
import { Logo } from '../Logo';
import { c } from '../../utils';
import { useTranslation, importTranslations } from '../../utils/i18n';
importTranslations('footer', require.context('./languages/', true));

const CURRENT_YEAR = (new Date().getFullYear());

const translatedKeys = new Set([
  'copyright',
  'aboutText',
  'aboutWhoop',
  'company',
  'ourMission',
  'careers',
  'contact',
  'joinUs',
  'signIn',
  'orderStatus',
  'support',
  'referAFriend',
  'termsOfService',
  'privacyPolicy'
]);
/**
 * Primary UI component for user interaction
 */

const defaultLinkGroups = [
  {
    groupName: 'company',
    links: [
      { label: 'ourMission', href: 'https://www.whoop.com/our-mission', target: '_blank' },
      { label: 'careers', href: 'https://www.whoop.com/careers', target: '_blank' },
      { label: 'contact', href: 'https://support.whoop.com/hc/en-us', target: '_blank' },
    ]
  },
  {
    groupName: 'Join Us',
    groupLink: '/',
    links: [
      { label: 'joinUs', href: 'https://join.whoop.com', target: '_blank' },
      { label: 'signIn', href: 'https://app.whoop.com/login', target: '_blank' },
      { label: 'orderStatus', href: 'https://orderstatus.whoop.com/orderlookup', target: '_blank' },
      { label: 'support', href: 'https://support.whoop.com/hc/en-us', target: '_blank' },
      { label: 'Refer a Friend', href: 'https://app.whoop.com/referafriend', target: '_blank' },
    ]
  },
];

const defaultPolicyLinks = [
  { label: 'Terms of Service', href: 'https://www.whoop.com/termsofuse/', target: '_blank' },
  { label: 'Privacy Policy', href: 'https://www.whoop.com/privacy/', target: '_blank' }
];

export const Footer = ({ className, aboutText = 'aboutText', mediaLinks, linkGroups = defaultLinkGroups, policyLinks = defaultPolicyLinks, ...props }) => {
  const { t } = useTranslation('footer');
  const T = (key, options) => {
    if (translatedKeys.has(key)) {
      return t(key, options);
    }
    return key;
  };
  return (
    <div
      className={c(className, styles.footer, 'whoop-ui')}
      {...props}
    >
      <div className={styles.container}>
        <div className={styles.about}>
          {aboutText && <Logo variant="square" className={styles.logo} />}
          {aboutText && <h2>{T('aboutWhoop')}</h2>}
          {aboutText && <p className={styles.aboutText}>{T(aboutText)}</p>}
        </div>

        {linkGroups &&
          <div className={styles.linkGroups}>
            {linkGroups.map((group, groupKey) => (
              <div className={styles.linkGroup} key={groupKey}>
                <h2 className={group.groupLink ? styles.clickable : null}>
                  {group.groupName}
                </h2>
                <ul>
                  {group.links.map((link, linkKey) => (
                    <li key={linkKey}>
                      <a className={c(styles.link, 'no-color')} {...link}>{T(link.label)}</a>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>}
      </div>

      <div className={styles.policyLinks}>
        <span>{T('copyright', { copyrightSymbol: '\u00A9', year: CURRENT_YEAR })}</span>
        {policyLinks && policyLinks.map((link, linkKey) => (
          <a key={linkKey} {...link} className='no-color'>{T(link.label)}</a>
        ))}
      </div>

    </div>
  );
};

Footer.propTypes = {
  /**
   * Text for ABOUT WHOOP section. If not included, section will not be included
   */
  aboutText: PropTypes.string,
  /**
   * Various links to include in the footer.
   */
  linkGroups: PropTypes.arrayOf(PropTypes.shape({
    /**
     * Name of link group
     */
    groupName: PropTypes.string.isRequired,
    /**
     * Optional link for group
     */
    groupLink: PropTypes.string,
    /**
     * List of links
     */
    links: PropTypes.arrayOf(PropTypes.shape({
      /**
       * Link label
       */
      label: PropTypes.string.isRequired,
      /**
       * Note: all properties are passed down to a tag, so all valid a tag props work.
       * onClick, href, target etc.. will work
       */
    })),
  })),
  /**
   * Footer links like ToS & privacy policy (combined with copyright tag)
   */
  policyLinks: PropTypes.arrayOf(PropTypes.shape({
    /**
     * Link label
     */
    label: PropTypes.string.isRequired,
    /**
     * Note: all properties are passed down to a tag, so all valid a tag props work.
     * onClick, href, target etc.. will work
     */
  }))
};
