import React, { useEffect, useState } from "react";
import { Button } from '../../Button';
import { c } from '../../../utils';
import { RadioInputProps } from 'types/RadioInputs';

import styles from './radio-buttons.module.scss';
import { ButtonSizes, ButtonVariants } from 'types/Buttons';

export const RadioButtons = ({
  className,
  name,
  onChange,
  options,
  size,
  useExternalState,
  allowNone = false,
  value,
  ...props
}: RadioInputProps): JSX.Element => {
  const [selectedValue, setSelectedValue] = useState(value);
  
  // set value when parent updates value
  useEffect(() => {
    if (value != null) {
      setSelectedValue(value)
    } else if (!allowNone) {
      setSelectedValue(options?.[0]?.value)
    }
  }, [value]);

  if (!options.length) return null;

  const changeValue = (value: string | number) => {
    if (value == selectedValue) return;
    onChange && onChange(value);
    setSelectedValue(value);
  };

  const actualValue = useExternalState ? value : selectedValue;

  return (
    <div
      aria-labelledby={name}
      className={c(styles.radioButtons, className)}
      role='radiogroup'
      {...props}>
      {
        options?.map(({ disabled, label, style, value }) => {
          const selected = value == actualValue;
          return (
            <Button
              className={c(styles.button, styles[`button-${size}`], className, selected && styles.selected)}
              disabled={disabled}
              key={`${name}-${value}`}
              label={label}
              onClick={() => changeValue(value)}
              size={(size || 'medium') as ButtonSizes}
              style={style}
              variant={'primary' as ButtonVariants}
            />
          );
        })
      }
    </div>
  );
};
