import React, { useState, useEffect } from 'react';
import useForm from 'react-hook-form';
import OrderContent from './order-content';
import InputField from './input-field';
import apiService from '../services/api-service';
import AccountHeader from './account-header';
import { isDeepLink } from './shipping-info';
import { logout } from '../services/auth-client';
import { grabOrder } from '../services/support';
import { dataDogLog, gtagCommand } from '../services/monitoring';
import { logAmplitudeEvent } from '../services/monitoring';
import styles from '../css/look-up-order.module.css';
import {useTranslation} from "react-i18next";

const LookUpOrder = (props) => {
  const { t } = useTranslation('lookupOrder');
  const [ order, setOrder ] = useState(null);
  const [ hasError, setHasError ] = useState(null);
  const [ errorNotFound, setErrorNotFound ] = useState(false);
  const [ errorPreconditionFailed, setErrorPreconditionFailed ] = useState(false);
  const [ errorInvalidEmail, setErrorInvalidEmail ] = useState(false);
  const [ email, setEmail ] = useState(null);
  const [ hasGift, setHasGift ] = useState(null);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isDisabled, setIsDisabled ] = useState(true);
  const [ isLoggedIn, setIsLoggedIn ] = useState(false);
  const [ isUpgrade, setIsUpgrade ] = useState(false);
  const [ userEmail, setUserEmail ] = useState('');
  const [ hasCredentials, setHasCredentials ] = useState(props.hasCredentials);
  const { handleSubmit, register, watch } = useForm();
  let orders = null;

  const notFoundErrorMessage = t('thereWasAnError') + ' ' + t('notFoundErrorMessage');
  const preconditionFailedErrorMessage = t('thereWasAnError') + ' ' + t('preconditionFailedErrorMessage');
  const invalidEmailErrorMessage = t('thereWasAnError') + ' ' + t('invalidEmailErrorMessage');
  let values = {
    orderId: null,
    orderZip: null,
    orderEmail: userEmail
  };

  const lookUpOrder = (values) => {
    resetValues();
    if (userEmail && !props.data?.orderEmail) {
      const validEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
      if (!validEmail.test(userEmail)) {
        orderError('invalid email');
        return;
      }
    }
    if (!values.orderEmail) {
      values.orderEmail = userEmail;
      values.isRegionWithNoZipCode = false;
    }
    inputCheckAndGaTracking(values);

    apiService.getOrderLookUp(values).then((result) => {
      displayOrders(result);
      gtagCommand('Order-Status', 'confirm', 'Order Look Up - Complete');
    }, (error) => {
      orderError(error.message, values);
    });
  };

  useEffect(() => {
    if (props.data && (props.data.orderId || props.data.orderEmail)) {
      lookUpOrder(props.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  const inputCheckAndGaTracking = (values) => {
    values.orderId = values.orderId && (values.orderId[0] === "#" ? values.orderId.substr(1) : values.orderId.toUpperCase());

    if (values.orderId && values.orderZip) {
      gtagCommand('Order-Status', 'click', 'Order Id and Order Zip Look Up');
    } else if (!values.orderId && values.orderZip && values.orderEmail.length > 0) {
      gtagCommand('Order-Status', 'click', 'Order Email Look Up');
    } else if (values.orderId && !values.orderZip && values.orderEmail.length > 0) {
      gtagCommand('Order-Status', 'click', 'Order Id and Order Email Look Up');
    }
  };

  const resetValues = () => {
    logout();
    setOrder(null);
    setHasError(false);
    setErrorNotFound(false);
    setIsLoading(true);
    setIsLoggedIn(false);
    setHasCredentials(false);
  };

  const displayOrders = (response) => {
    setOrder(response);
    setEmail(response.length && response[0].email);
    setHasGift(response.length && response[0].isGift);
    setIsUpgrade(response.length && response[0].isUpgrade);
    setIsLoading(false);
  };

  const orderError = (err, obj) => {
    console.warn(err);
    setErrorNotFound(err.toLowerCase() === 'not found');
    setErrorPreconditionFailed(err.toLowerCase() === 'precondition failed');
    setErrorInvalidEmail(err.toLowerCase() === 'invalid email');
    setHasError(true);
    setIsLoading(false);
    dataDogLog('orderLookUp', obj);
  };

  useEffect(() => {
    if (hasError) {
      logAmplitudeEvent('Viewed Order Status Page', {
        category: 'Order Status',
        has_order_entered: isDeepLink(values.orderId, values.orderZip, true) ? 'deeplink' : 'search',
        error_response: errorNotFound ? notFoundErrorMessage : preconditionFailedErrorMessage
      })    
    }
  }, [hasError, errorNotFound, values.orderId, values.orderZip, notFoundErrorMessage, preconditionFailedErrorMessage]);

  const handleEnterKey = (e) => {
    if (e.target.name === 'orderEmail') {
      setUserEmail(e.target.value);
    }
    const { orderId, orderZip } = watch();

    values = {
      orderId: orderId,
      orderZip: orderZip,
      orderEmail: userEmail
    };

    if (e.key === 'Enter' && !isDisabled) {
      lookUpOrder(values);
    }
  };

  useEffect(() => {
    const { orderId } = watch();
    setIsDisabled(!orderId || !userEmail);
  }, [watch, userEmail]);

  if (hasCredentials) {
    const data = {
      orderId: null,
      orderEmail: null,
      zip: null
    };

    if (!isLoggedIn) {
      setIsLoggedIn(true);
      setIsLoading(true);
      grabOrder(data, displayOrders, orderError);
    }
  }

  if (order && order.length) {
    orders = order.map((item, index) => {
      return <OrderContent key={index} data={item} displayOrders={displayOrders} resetValues={resetValues} />
    });
  }

  return (
    <div>
      { email && !isUpgrade ? <AccountHeader email={email} isGift={hasGift} /> : null }
      <div className={ styles.border }>
        { !order ?
          <div className={ styles.container } style={{display: !isLoggedIn || (isLoggedIn && hasError) ? 'block' : 'none'}}>
            <div className={ styles.title }>{t('orderLookup')}</div>
            <div className={styles.lookUpContainer}>
              <div className={styles.orderContainer}>
                <div>
                  <div className={styles.numbers}>
                    <div className={styles.middle}>1</div>
                  </div>
                  <InputField
                    type="text"
                    name="orderId"
                    placeholder={t('orderNumber')}
                    inputRef={register}
                    testId="order-id"
                    handleEnterKey={handleEnterKey} />
                </div>
              </div>
              <div className={styles.zipField}>
                <div className={styles.numbers}>
                  <div className={styles.middle}>2</div>
                </div>
                <InputField
                  type="text"
                  name="orderEmail"
                  placeholder={t('emailAddress')}
                  inputRef={register}
                  testId="order-email"
                  handleEnterKey={handleEnterKey} />
              </div>
            </div>
            <div className={styles.bottomCTA}>
              <div className={ styles.submitBtn }>
                <button className={ styles.btn } disabled={isDisabled} data-test-id="look-up-order" onClick={handleSubmit(lookUpOrder)}>
                  {t('lookupOrder')}
                </button>
              </div>
            </div>
          </div>
          : null }
        { isLoading ? <div className={ !isLoggedIn || (isLoggedIn && hasError) ? styles.loading : styles.loadingBig }>&nbsp;</div> : orders }
        <div>
          { order && !order.length ?
              <div className={ styles.noOrders }>There is no available order
                <div className={ styles.otherOrders } style={{display: isLoggedIn ? 'block' : 'none'}}>
                  <a href="/" onClick={resetValues}>View Another Order</a>
                </div>
              </div> : null }
          { hasError ?
            <div className={ styles.noOrders }>
              { errorNotFound ?
                <span>
                  { notFoundErrorMessage }&nbsp;
                </span>
                : null }
              { errorPreconditionFailed ?
                <span>
                  { preconditionFailedErrorMessage }&nbsp;
                </span>
                : null }
              { errorInvalidEmail ?
                <span>
                  { invalidEmailErrorMessage }&nbsp;
                </span>
                : null }
              <span dangerouslySetInnerHTML={{__html: t('ifYouAreStillEncounteringProblems')}} />
            </div>
            : null
          }
        </div>
      </div>
    </div>
  );
};

export default LookUpOrder;