import React, { useState } from 'react';
import { Button, Modal } from '@whoop/web-components';
import { useTranslation } from 'react-i18next';
import apiService from '../services/api-service';
import { dataDogLog, gtagCommand, logAmplitudeEvent } from '../services/monitoring';
import styles from '../css/modals.module.css';
import cancelStyles from '../css/cancel-order.module.css';

/* eslint react/prop-types: 0 */
const CancelUserOrder = (props) => {
  const [hasError, setHasError] = useState(null);
  const { data, closeModal, displayOrders } = props;
  const [isLoading, setIsLoading] = useState(false);

  const isReservation = Boolean(data.isReservation);

  const cancelData = {
    opportunityId: data.opportunityId,
    charge: data.orderCharge,
    shopifyOrder: data.shopifyOrder,
    stripeOrderId: data.stripeOrderId,
    whoopOrderId: data.whoopOrderId,
    orderId: data.orderId,
  };

  const orderError = (err) => {
    console.log(err);
    setHasError(true);
    setIsLoading(false);
    dataDogLog('cancelOrder', data);
  };

  const cancel = (event) => {
    event.preventDefault();
    setIsLoading(true);

    apiService.cancelOrder(cancelData).then(() => {
      gtagCommand('Order-Status', 'confirm', 'Canceled Order - Complete');
      logAmplitudeEvent('Cancel Order Completed', {
        joined_whoop_pro: data.isWhoopPro,
        membership: data.trialDays,
        currency: data.currencyType,
        reservation: data.isReservation || false,
      });
      displayOrders([{
        canceled: true,
        canceledId: data.orderId,
      }]);
      closeModal();
    }, (error) => {
      orderError(error.message);
    });
  };

  return (
    <CancelOrderModalV2
      isLoading={isLoading}
      hasError={hasError}
      closeModal={closeModal}
      cancel={cancel}
      isReservation={isReservation}
    />
  );
};

const CancelOrderModalV2 = ({
  isLoading, hasError, cancel, closeModal, isReservation,
}) => {
  const { t } = useTranslation('cancelOrder');
  return (
    <Modal show onClose={closeModal}>
      <div className={cancelStyles.modal}>
        <div>
          <h1 className={cancelStyles.modalHeader}>{t('cancelOrder')}</h1>
        </div>
        <div className={cancelStyles.modalBodyText}>{isReservation ? t('cancelReservationDisclaimer') : t('cancelDisclaimer')}</div>
        <div className={cancelStyles.modalBodyText}>{t('areYouSure')}</div>
        <div className={cancelStyles.nevermindCta}>
          <Button onClick={closeModal} disabled={isLoading} variant="primary" label={t('nevermind')} />
        </div>
        { isLoading ? <div className={styles.loading}>&nbsp;</div> : null }
        { !isReservation && <button onClick={cancel} className={cancelStyles.cancelLink} type="submit">{t('iStillWantTo')}</button> }
        { isReservation && (
          <div>
            <hr />
            <div>{t('youWillNotBeCharged')}</div>
            <Button
              onClick={cancel}
              disabled={isLoading}
              className={cancelStyles.cancelCta}
              variant="normal"
              label={t('iStillWantTo')}
            />
          </div>
        )}
        { hasError && <div className={styles.serverAlert}>{t('errorMessage')}</div> }
      </div>
    </Modal>
  );
};

export default CancelUserOrder;
