// IntersectionObserver isn't available in test environment.
// Need to mock class if this is used in your repo.
// You can put it in setupTests.js and override it in any tests that specifically use this hook
export const setupIntersectionObserverMock = (isIntersecting: boolean = true) => {
  const entry: IntersectionObserverEntry = {
    boundingClientRect: {} as DOMRectReadOnly,
    intersectionRatio: 1,
    intersectionRect: {} as DOMRectReadOnly,
    isIntersecting,
    rootBounds: null,
    target: {} as Element,
    time: 1,
  };
  const takeRecords = () => [entry];

  class MockIntersectionObserver implements IntersectionObserver {
    readonly root: Element | null = null;

    readonly rootMargin: string = '';

    readonly thresholds: ReadonlyArray<number> = [];

    disconnect = jest.fn();

    observe = jest.fn();

    takeRecords: () => IntersectionObserverEntry[] = takeRecords;

    unobserve: () => void = jest.fn();

    constructor(callback: (entries: IntersectionObserverEntry[],
                           observer: IntersectionObserver) => void) {
      callback(takeRecords(), this);
    }
  }

  window.IntersectionObserver = MockIntersectionObserver;
};
