import React, { useState, useEffect } from 'react';
import LogIn from './login';
import UpdateUserAddress from './update-user-address';
import CancelOrder from './cancel-user-order';
import ShippingInfo from './shipping-info';
import styles from '../css/order-content.module.css';
import { hasOrderNumber } from '../services/auth-client';
import { gtagCommand, logAmplitudeEvent } from '../services/monitoring';
import { disableMobileScroll, enableMobileScroll } from '../services/support';
import {useTranslation} from "react-i18next";

const OrderContent = (props) => {
  const { t } = useTranslation('orderContent');
  const [ showLogin, setLogin ] = useState(false);
  const [ showCancelOrder, setCancelOrder ] = useState(false);
  const [ showEditAddress, setEditAddress ] = useState(false);
  const [ isAvailable, setIsAvailable ] = useState(false);
  const [ isCanceled, setIsCanceled ] = useState(false);
  const [ modalType, setModalType ] = useState(null);
  const { data, displayOrders, resetValues } = props;
  const [ userCity, setUserCity ] = useState(data.city);
  const [ userState, setUserState ] = useState(data.state);
  const [ userZip, setUserZip ] = useState(data.zip);
  const [ userCountry, setUserCountry ] = useState(data.country);
  const [ userAddress1, setUserAddress1 ] = useState(data.address1);
  const [ userAddress2, setUserAddress2 ] = useState(data.address2);
  const orderId = data.orderId ? data.orderId : data.stripeOrderId;

  useEffect(() => {
    setIsCanceled(data.canceled);
    setUserAddress1(data.address1);
    setUserAddress2(data.address2);
    setUserCity(data.city);
    setUserState(data.state);
    setUserZip(data.zip);
    setUserCountry(data.country);

    if (data.hasAccess) {
      setIsAvailable(data.hasAccess);
    } else {
      setIsAvailable(hasOrderNumber(orderId));
    }
  }, [orderId, data]);

  const updateAccountAddress = (data) => {
    setUserAddress1(data.address1);
    setUserAddress2(data.address2);
    setUserCity(data.city);
    setUserState(data.state);
    setUserZip(data.shipZip);
    setUserCountry(data.country);
    closeModal();
  };

  const cancelUserOrder = (event) => {
    event.preventDefault();

    logAmplitudeEvent('Cancel Order Clicked', {
      'joined_whoop_pro': data.isWhoopPro,
      'membership': data.trialDays,
      'currency': data.currencyType,
      'reservation': data.isReservation || false
    });

    if (isAvailable) {
      setModalType(null);
      setCancelOrder(true);
      gtagCommand('Order-Status', 'click', 'Cancel Order Modal');
    } else {
      setLogin(true);
      setModalType('cancel');
      gtagCommand('Order-Status', 'click', 'Modal Log In - Cancel Order');
    }

    disableMobileScroll();
  };

  const changeUserAddress = (event) => {
    event.preventDefault();

    if (isAvailable) {
      setModalType(null);
      setEditAddress(true);
      gtagCommand('Order-Status', 'click', 'Update Shipping Address Modal');
    } else {
      setLogin(true);
      setModalType('address');
      gtagCommand('Order-Status', 'click', 'Modal Log In - Shipping Address');
    }

    disableMobileScroll();
  };

  const closeModal = () => {
    enableMobileScroll();
    setLogin(false);
    setCancelOrder(false);
    setEditAddress(false);
  };

  const switchModal = () => {
    if (modalType === 'cancel') {
      setCancelOrder(true);
    } else if (modalType === 'address') {
      setEditAddress(true);
    }
  };

  const shippingData = {
    city: userCity,
    state: userState,
    zip: userZip,
    address1: userAddress1,
    address2: userAddress2,
    country: userCountry,
    hasAddressLine2: userAddress2 && userAddress2.length > 0
  };

  return (
    <div className={ styles.container }>
      { isCanceled && (
        <div className={ styles.noOrders } style={{display: isCanceled ? 'block' : 'none' }}>
          {t('orderCancelled', { orderNumber: data.canceledId })}
          <div className={ styles.otherOrders }>
            <a href="/" onClick={resetValues}>{t('viewAnotherOrder')}</a>
          </div>
        </div>
      )}
      { !isCanceled ? <ShippingInfo orderId={orderId}
                                    userZip={userZip}
                                    orderData={data}
                                    shippingData={shippingData}
                                    changeUserAddress={changeUserAddress}
                                    cancelUserOrder={cancelUserOrder}
                                    resetValues={resetValues} /> : null }
      { showLogin ? <LogIn closeModal={closeModal}
                           data={data}
                           switchModal={switchModal} /> : null }
      { showEditAddress ? <UpdateUserAddress data={data}
                                             updateAccountAddress={updateAccountAddress}
                                             closeModal={closeModal}
                                             displayOrders={displayOrders}
                                             modalType={modalType} /> : null }
      { showCancelOrder ? <CancelOrder data={data}
                                       closeModal={closeModal}
                                       displayOrders={displayOrders}
                                       modalType={modalType} /> : null }
    </div>
  );
}

export default OrderContent;
