import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { DEFAULT_LANGUAGE, generateFilteredValidLangs } from '@whoop/i18n';

const filteredLangs = generateFilteredValidLangs(
  navigator.languages,
);

const FILTERED_VALID_LANGUAGES = filteredLangs.length ? filteredLangs : [DEFAULT_LANGUAGE];

i18n
  .use(initReactI18next)
  .use(Backend)
  .use(LanguageDetector)
  .init({
    backend: {
      loadPath: '/languages/{{lng}}/{{ns}}.json',
    },
    fallbackLng: DEFAULT_LANGUAGE,
    supportedLngs: FILTERED_VALID_LANGUAGES,
    nonExplicitSupportedLngs: true,
    debug: process.env.REACT_APP_STAGE === 'development',
  });

export default i18n;
