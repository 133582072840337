import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import apiService from '../services/api-service';
import { dataDogLog, gtagCommand } from '../services/monitoring';
import ReservationInfo from './reservation-info';
import styles from '../css/look-up-reservation.module.css';
import {useTranslation} from "react-i18next";

const LookUpReservation = ({ stripeOrder, orderZip, orderEmail }) => {
  const [order, setOrder] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation('lookupOrder');

  useEffect(() => {
    const credentials = {
      stripeOrder,
      orderZip,
      isRegionWithNoZipCode: false
    };

    if (orderEmail) {
      credentials.isRegionWithNoZipCode = true;
      credentials.orderEmail = orderEmail;
    }

    apiService.getStripeOrderLookUp(credentials)
      .then(() => {
        lookUpOrder(credentials);
      }).catch(error => {
        console.error(error.message);
        setHasError(true);
        setIsLoading(false);
        dataDogLog('stripeLookUp', credentials);
        dataDogLog('stripeLookUpError', error);
      });
  }, [stripeOrder, orderZip, orderEmail]);

  const lookUpOrder = (values) => {
    apiService.getOrderLookUp(values).then((result) => {
      gtagCommand('Order-Status', 'confirm', 'Reservation Look Up - Complete');
      setOrder(result[0]);
      setIsLoading(false);
    }).catch(error => {
      console.error(error.message);
      setHasError(true);
      setIsLoading(false);
      dataDogLog('orderLookUp', values);
      dataDogLog('orderLookUpError', error);
    });
  };

  if (order && (!order.reservationState || order.reservationState === 'reserved')) {
    let searchParams = `?stripe=${stripeOrder}&zip=${orderZip}`;

    if (orderEmail) {
      searchParams += `&email=${orderEmail}`;
    }

    return (
      <Redirect
        to={{
          pathname: "/",
          search: searchParams,
        }}
      />
    );
  }

  return (
    <>
      {isLoading && <div className={styles.loadingBig}>&nbsp;</div>}
      {hasError && (
        <div className={styles.noOrders}
             dangerouslySetInnerHTML={{ __html: t('linkedOrderError') }}>
        </div>
      )}
      {order && <ReservationInfo order={order} />}
    </>
  );
};

export default LookUpReservation;
