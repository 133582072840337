import React, { useEffect, useState } from "react";
import { ProductDetailsGrid, ProductDetailsGridProps } from "../ProductDetailsGrid";
import { ProductItem, ProductNode } from '../../../types/Products'
import { MultiProductSelect } from '../../MultiProductSelect'
import {
  getDescriptionFromSelection, getEngravingTypesFromSelection,
  getMediaFromSelection
} from "../../../utils/productUtils";
import { ProductMedia } from "../ProductMedia";
import { EngravingPreview, EngravingSelector } from "../Engraving/Engraving";
import { EngravingInfo } from "../Engraving";
import { isEngravingImageForType, matchEngravingPreviewImage } from "../Engraving/engravingPreviewUtils";
import { importTranslations, useTranslation } from "../../../utils/i18n";
import { getEngravingError } from "../Engraving/engravingUtils";
import { preloadImage } from "../../../utils";
import { AnalyticsFunction } from "../../../types";

// This component depends on translations from the engraving component 
// Explicitly importing to enable tree shaking
importTranslations("engraving", require.context("../Engraving/languages/", true));

export interface ProductDetailsProps extends Omit<ProductDetailsGridProps, "onChange" | "media"> {
  node: ProductNode;
  value?: ProductItem;
  onChange?: (selection: ProductItem, engraving?: EngravingInfo) => any;
  onSizeGuideClick?: React.MouseEventHandler; // if present will show size-guide
  onAnalyticsEvent?: AnalyticsFunction;
  // engraving props
  engravingValue?: EngravingInfo;
  showEngravingError?: boolean;
  specialSale?: boolean;
}

export function ProductDetails({
  node,
  children,
  onChange,
  value,
  onSizeGuideClick,
  onAnalyticsEvent,
  engravingValue,
  showEngravingError,
  specialSale,
  ...props
}: ProductDetailsProps) {
  const { t } = useTranslation("engraving");
  const [selection, _setSelection] = useState<ProductItem>();
  const engravingTypes = getEngravingTypesFromSelection(selection, node);
  const [engraving, _setEngraving] = useState<EngravingInfo>(engravingValue);
  const description = getDescriptionFromSelection(selection, node);
  const engravingError = getEngravingError(engraving, t);
  const setSelection = (item: ProductItem) => {
    _setSelection(item);
    onChange && onChange(item, !engravingError && engraving);
  }
  const setEngraving = (value: EngravingInfo) => {
    _setEngraving(value);
    const newError = getEngravingError(value, t);
    onChange && onChange(selection, !newError && value);
  }

  let media = getMediaFromSelection(selection, node);
  if (!!engravingTypes && engraving?.type) {
    media = media?.filter(media => isEngravingImageForType(media?.url, engraving.type));
  }

  useEffect(() => media?.forEach(media => preloadImage(media?.url)), [media]);

  return (
    <ProductDetailsGrid
      media={media?.map((item) => {
        if (matchEngravingPreviewImage(item?.url)) {
          return <EngravingPreview engraving={engraving} media={item} key={item.id} />
        }
        return <ProductMedia {...item} key={item.id} />;
      })}
      onImageChange={(index) => {
        onAnalyticsEvent && onAnalyticsEvent('Product Details Viewed Image', {
          product_handle: node?.product_info?.handle,
          image_index: index,
        });
      }}
      description={description}
      onAnalyticsEvent={onAnalyticsEvent}
      {...props}
    >
      <MultiProductSelect
        node={node}
        value={value}
        specialSale={specialSale}
        onChange={setSelection}
        onSizeGuideClick={onSizeGuideClick}
        onOptionSelected={(option, type, item) => {
          onAnalyticsEvent && onAnalyticsEvent("Product Details Option Selected", {
            product: node?.product_info?.handle,
            sku: item?.sku,
            option: option,
            option_type: type,
            item: item,
          })
        }}
      />
      {!!engravingTypes &&
        <EngravingSelector
          engravingTypes={engravingTypes}
          value={engravingValue}
          error={showEngravingError && engravingError}
          onChange={setEngraving}
        />}
      {children}
    </ProductDetailsGrid>
  );
}
