import React from 'react';
import {importTranslations, useTranslation} from '../../utils/i18n';
import styles from './learnMore.module.scss';
import {classes} from "../../utils";

importTranslations('learn-more', require.context('./languages/', true))

export interface LearnMoreProps extends React.HTMLAttributes<HTMLDivElement> {
  onLearnMore: () => void;
}

export function LearnMore({
  onLearnMore,
  className,
}: LearnMoreProps) {
  const { t } = useTranslation('learn-more')

  return (
    <a className={classes(styles.learnMore, 'no-color', className)} onClick={onLearnMore}>
      {t('learnMore')}
    </a>
  );
}
