import React from 'react'
import '../../index.module.scss'
import s from './alert.module.scss'
import { c } from '../../utils'
import { Icon } from '../Icon'
import { Alerts } from '../../types/Alerts'
import { StatusTagThemes } from '../../types/StatusTagThemes'
import { BasicComponentProps } from '../../types'
import { Button } from '../Button/'
import { ButtonSizes, ButtonVariants } from '../../types/Buttons'
import { IconButton } from '../IconButton'
import { LayeredImage } from '../LayeredImage'

export type AlertProps = {
  type: Alerts
  theme?: StatusTagThemes
  iconName?: string
  actionLabel?: string
  actionIcon?: string
  actionNode?: React.ReactNode
  onAction?: React.MouseEventHandler
  image?: string | string[]
  bannerLabel?: string
  bannerClassName?: string
  disabled?: boolean
  actionPosition?: 'right' | 'bottom'
} & BasicComponentProps

const defaultTheme = StatusTagThemes.DEFAULT

const DEFAULT_ICONS = {
  info: 'education',
  success: 'check',
  error: 'attention',
  'unite-error': 'attention',
  warning: 'attention',
  pro: 'logo_pro',
  neutral: 'education'
}

export const Alert = ({
  type,
  children,
  iconName,
  theme = defaultTheme,
  actionLabel,
  actionIcon,
  actionNode,
  image,
  onAction,
  onClick,
  bannerLabel,
  bannerClassName,
  disabled = false,
  actionPosition = 'right',
  className,
  ...props
}: AlertProps) => {
  const handleAction = (event: React.MouseEvent) => {
    // if no action is given users can still use onClick as a global trigger
    if (onAction) {
      onAction(event)
      event.preventDefault()
      event.stopPropagation()
    }
  }

  return (
    <>
      {bannerLabel && (
        <div className={c(s.alertHeader, bannerClassName)}>{bannerLabel}</div>
      )}
      <div
        className={c(
          'whoop-ui',
          s[`alert-${type}`],
          `theme-${theme}`,
          !!onClick && s.clickable,
          s.frame,
          className
        )}
        onClick={onClick}
        {...props}
      >
        <div className={c('whoop-ui', s.container)}>
          {!image && (
            <Icon
              name={iconName || DEFAULT_ICONS[type] || 'education'}
              className={c(
                `theme-${theme}`,
                s.iconSmall,
                s[`icon-${type}`]
              )}
            />
          )}
          {image && image instanceof Array && (
            <LayeredImage>
              {image.map((src) => (
                <img src={src} />
              ))}
            </LayeredImage>
          )}
          {image && !(image instanceof Array) && <img src={image} />}
          <div className={c(s.text, `theme-${theme}`)}>{children}</div>
          {actionPosition === 'right' &&
            actionLabel &&
            !actionIcon &&
            !actionNode && (
              <Button
                size={ButtonSizes.SMALL}
                variant={ButtonVariants.PRIMARY}
                label={actionLabel}
                className={c(s.action, s.actionRight)}
                onClick={handleAction}
                disabled={disabled}
              />
            )}
          {actionPosition === 'right' && actionIcon && !actionNode && (
            <IconButton
              size={ButtonSizes.SMALL}
              variant={ButtonVariants.NORMAL}
              name={actionIcon}
              className={c(s.action, s.actionRight)}
              data-tooltip={actionLabel}
              onClick={handleAction}
              disabled={disabled}
            />
          )}
          {actionPosition === 'right' && actionNode}
        </div>
        {![StatusTagThemes.UNITE, StatusTagThemes.JOIN_FLOW].includes(
          theme
        ) && (
          <div className={c(s.bottomContainer)}>
            {actionPosition !== 'right' &&
              actionLabel &&
              !actionIcon &&
              !actionNode && (
                <Button
                  size={ButtonSizes.SMALL}
                  variant={ButtonVariants.PRIMARY}
                  label={actionLabel}
                  className={c(s.action, s.actionBottom)}
                  onClick={handleAction}
                  disabled={disabled}
                />
              )}
            {actionPosition !== 'right' && actionIcon && !actionNode && (
              <IconButton
                size={ButtonSizes.SMALL}
                variant={ButtonVariants.NORMAL}
                name={actionIcon}
                className={c(s.action, s.actionBottom)}
                data-tooltip={actionLabel}
                onClick={handleAction}
                disabled={disabled}
              />
            )}
            {actionPosition !== 'right' && actionNode}
          </div>
        )}
      </div>
    </>
  )
}
