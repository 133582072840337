import React from 'react';
import { useController, useFormContext, Validate, FieldValues } from 'react-hook-form';
import { TextArea } from '../TextArea';
import { BasicComponentProps } from "../../types";

export type FormTextAreaProps = {
  label: string;
  name: string;
  defaultValue?: string;
  placeholder?: string;
  characterLimit?: number;
  required?: {
    value: boolean;
    message: string;
  };
  validate?: Validate<FieldValues>;
  pattern?: {
    value: RegExp;
    message: string;
  };
  minLength?: {
    value: number;
    message: string;
  };
  maxLength?: {
    value: number;
    message: string;
  };
} & BasicComponentProps;

export const FormTextArea = ({
  name,
  required,
  validate,
  pattern,
  defaultValue,
  minLength,
  maxLength,
  ...props
}: FormTextAreaProps) => {
  const { control } = useFormContext();

  const {
    field: { onChange, name: fieldName, value },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules: { required, minLength, maxLength, validate, pattern },
    defaultValue,
  });

  return (
    <TextArea
      name={fieldName}
      value={value}
      onChange={onChange}
      errorValue={error && error.message}
      {...props}
    />
  );
};
