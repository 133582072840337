import React from 'react'
import { importTranslations, useTranslation } from '../../utils/i18n'
import { c } from '../../utils'
import styles from './loading.module.scss'
import { LoadingSizeType } from '../../types/Loading'
// @ts-ignore
import whoopLoading from '../../assets/whoop-loading.gif'

importTranslations('loading', require.context('./languages/', true))

export interface LoadingProps extends React.HTMLAttributes<HTMLDivElement> {
  size?: LoadingSizeType
}

export function Loading({
  size = LoadingSizeType.MEDIUM,
  className,
  ...props
}: LoadingProps) {
  const { t } = useTranslation('loading')

  return (
    <img
      className={c(
        styles.loadingLogo,
        styles[`loadingLogo-${size}`],
        className
      )}
      src={whoopLoading}
      alt={t('loading')}
      {...props}
    />
  )
}
