import { Button } from '../../../Button'
import React, { useEffect, useRef, useState, KeyboardEvent } from 'react'
import { c } from '../../../../utils'
import styles from './quantitySelector.module.scss'
import { Icon } from '../../../Icon'
import { ButtonVariants } from '../../../../types/Buttons'

export interface QuantitySelectorProps
  extends React.HTMLAttributes<HTMLDivElement> {
  options: string[]
  onOptionSelected: (option: string) => void
  // if the first option is remove and thats not what we want to show, then we can pass in the selected option
  selectedOption?: string
}
export function QuantitySelector({
  className,
  options,
  selectedOption,
  onOptionSelected,
  ...props
}: QuantitySelectorProps) {
  const [showMenuOptions, setShowMenuOptions] = useState<boolean>(false)
  const [optionSelected, setOptionSelected] = useState<string>(selectedOption || options[0])
  const dropdownRef = useRef<HTMLDivElement>()

  const handleClickOutside = (event: MouseEvent) => {
    // check if click is outside of div
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setShowMenuOptions(false)
    }
  }

  useEffect(() => {
    // add event listener to document object
    document.addEventListener('click', handleClickOutside)

    // cleanup function to remove event listener
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  const handleOptionSelect = (option: string) => {
    setShowMenuOptions(false)
    onOptionSelected(option)
    setOptionSelected(option)
  }

  const checkIfEnterButton = (
    option: string,
    event: KeyboardEvent<HTMLDivElement>
  ) => {
    if (event.code === 'Enter') {
      handleOptionSelect(option)
    }
  }
  return (
    <div
      className={c(className, styles.dropdown, 'whoop-typography')}
      {...props}
    >
      <p className={c(styles.text, 'p5')}>{optionSelected}</p>
      <Button
        onClick={() => setShowMenuOptions(!showMenuOptions)}
        className={c(styles.dropdownButton)}
        variant={ButtonVariants.NORMAL}
        role='menu'
      >
        <Icon name='caret_down' className={styles.icon} />
      </Button>
      {showMenuOptions && (
        <div className={styles.dropdownMenu}>
          {options.map((option) => (
            <div
              key={option}
              className={styles.menuItem}
              onClick={() => handleOptionSelect(option)}
              onKeyDown={(event) => checkIfEnterButton(option, event)}
              role='menuitem'
              tabIndex={0}
            >
              <p className='p5'>{option}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default QuantitySelector
